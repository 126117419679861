import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'pay-teaser',
  templateUrl: './pay-teaser.component.html',
  styleUrls: ['./pay-teaser.component.scss'],
})
export class PayTeaserComponent {
  constructor(private _trasnslate: TranslateService) {}
}
