import { Component, Input, OnInit } from '@angular/core';
import { BusinessFacade } from '../../../core/facades/business.facade';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'orderapp-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  headerLogo!: string;

  @Input() showBackIcon = false;

  constructor(
    private _businessFacade: BusinessFacade,
    private translate: TranslateService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this._businessFacade.business$.data$.subscribe((res) => {
      this.headerLogo = res?.logoImageUrl ?? '';
    });
  }

  get language() {
    return localStorage.getItem('lng') || 'de';
  }

  onChangeLanguage(language: string) {
    localStorage.setItem('lng', language);
    this.translate.use(language);
    if (
      this.router.url.includes('food-menu') ||
      this.router.url.includes('beverage-menu')
    ) {
      window.location.reload();
    }
  }
}
