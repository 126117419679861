<div *ngIf="!isInhouse; else showInhouse">
  <div class="container" *ngIf="order">
    <div class="confirmation__header">
      <h1>{{ 'confirmation.Title' | translate }}</h1>
      <p>
        {{ 'confirmation.orderNumberText' | translate }} <br />
        <span *ngIf="!payFromQrCode">{{ order.orderReference }}</span>
      </p>
    </div>

    <div class="confirmation__pickout">
      <h3>
        {{
          serviceTypeModule === activatedModuleEnum.TakeAway
            ? ('confirmation.pickupText' | translate)
            : ('Gewählte Lieferzeit' | translate)
        }}
      </h3>
      <p>{{ pickupTime | date: 'dd.MM.yyyy, HH:mm' }}</p>
    </div>

    <div
      class="confirmation__download"
      (click)="onDownloadInvoice(order.orderId)"
    >
      <div class="confirmation__download--image" *ngIf="!isDownloadLoading">
        <i class="far fa-receipt"></i>
      </div>
      <div class="confirmation__download--image" *ngIf="isDownloadLoading">
        <mat-progress-spinner
          mode="indeterminate"
          color="primary"
          diameter="24"
        ></mat-progress-spinner>
      </div>
      <h3>{{ 'confirmation.downloadInvoiceButton' | translate }}</h3>
      <p>{{ 'confirmation.downloadInvoiceText' | translate }}</p>
    </div>

    <div *ngIf="!payFromQrCode" class="confirmation__address">
      <h3 [ngSwitch]="serviceTypeModule">
        <ng-container *ngSwitchCase="activatedModuleEnum.TakeAway">
          {{ 'confirmation.pickupAddress' | translate }}</ng-container
        >
        <ng-container *ngSwitchCase="activatedModuleEnum.Delivery">
          {{ 'confirmation.deliveryAddress' | translate }}</ng-container
        >
        <ng-container *ngSwitchDefault>'Lieferadresse'</ng-container>
      </h3>
      <p *ngIf="serviceTypeModule === activatedModuleEnum.TakeAway">
        {{ order.address?.street }},<br />
        {{ order.address?.postalCode }}
        {{ order.address?.city }}
      </p>
      <p *ngIf="serviceTypeModule === activatedModuleEnum.HotelService">
        {{ qrCode.area }}<br />
        Zimmer {{ qrCode.number }}
      </p>
      <p *ngIf="serviceTypeModule === activatedModuleEnum.Delivery">
        {{ order.fullName }}<br />
        {{ order.deliveryAddress }}<br />
        {{ order.deliveryZone?.zipCode }} {{ order.deliveryZone?.city }}
      </p>
    </div>

    <div *ngIf="!payFromQrCode" class="new-order__button">
      <button (click)="onNewOrder()">
        {{ 'confirmation.newOrderButton' | translate }}
      </button>
    </div>
  </div>
</div>

<!--
  ================
  In House Module
  ================
 -->

<ng-template #showInhouse>
  <div class="container" *ngIf="order">
    <div class="confirmation__header">
      <h1>{{ 'confirmation.Title' | translate }}</h1>
      <p>
        {{ 'confirmation.orderNumberText' | translate }} <br />
        <span *ngIf="!payFromQrCode">{{ order.orderReference }}</span>
      </p>
    </div>

    <div
      class="confirmation__download"
      (click)="onDownloadInvoice(order.orderId)"
    >
      <div class="confirmation__download--image" *ngIf="!isDownloadLoading">
        <i class="far fa-receipt"></i>
      </div>
      <div class="confirmation__download--image" *ngIf="isDownloadLoading">
        <mat-progress-spinner
          mode="indeterminate"
          color="primary"
          diameter="24"
        ></mat-progress-spinner>
      </div>
      <h3>{{ 'confirmation.downloadInvoiceButton' | translate }}</h3>
      <p>{{ 'confirmation.downloadInvoiceText' | translate }}</p>
    </div>

    <div *ngIf="!payFromQrCode" class="new-order__button">
      <button (click)="onNewOrder()">
        {{ 'confirmation.newOrderButton' | translate }}
      </button>
    </div>
  </div>
</ng-template>
