import { NgModule } from '@angular/core';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { ActivatedModuleComponent } from './components/activated-module/activated-module.component';
import { MenuCategoriesComponent } from './components/menu/menu-categories/menu-categories.component';
import { CommonModule } from '@angular/common';
import { MenuItemsComponent } from './components/menu/menu-items/menu-items.component';
import { MaterialModule } from './components/material.module';
import { MenuItemsDetailsComponent } from './components/menu/menu-items/menu-items-details/menu-items-details.component';
import { ReactiveFormsModule } from '@angular/forms';
import { InputCounterModule } from './components/button/input-counter/input-counter.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoaderAnimationComponent } from './components/loader-animation/loader-animation.component';
import { TranslateModule } from '@ngx-translate/core';
import { PaymentDialogComponent } from '../modules/shopping-cart/payment-dialog/payment-dialog.component';
import { LoaderComponent } from './components/loader/loader.component';
import { PayTeaserComponent } from './components/pay-teaser/pay-teaser.component';
import { ActiveMenuComponent } from './components/active-menu/active-menu.component';

const SHARED_COMPONENT = [
  HeaderComponent,
  FooterComponent,
  ActivatedModuleComponent,
  ActiveMenuComponent,
  MenuCategoriesComponent,
  MenuItemsComponent,
  MenuItemsDetailsComponent,
  PaymentDialogComponent,
  LoaderAnimationComponent,
  LoaderComponent,
  PayTeaserComponent,
];

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    InputCounterModule,
    BrowserAnimationsModule,
    TranslateModule.forChild({
      isolate: false,
    }),
  ],
  declarations: [...SHARED_COMPONENT],
  exports: [...SHARED_COMPONENT],
  providers: [],
})
export class SharedModule {}
