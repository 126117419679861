<ng-container
  *ngIf="{
    isNotLoading: businessFacade.business$.isNotLoading$ | async,
    hasNoError: businessFacade.business$.hasNoError$ | async,
    businessIsOpen: businessFacade.businessIsOpen$ | async,
    serviceTypes: businessFacade.serviceTypes$.data$ | async,
    preparationTimes: businessFacade.preparationTimes$.data$ | async,
    orderingTimes: businessFacade.orderingTimes$.data$ | async,
    address: (businessFacade.business$.data$ | async)?.address
  } as state"
>
  <div class="hotelservice__welcome">
    <ng-container *ngIf="state.isNotLoading && state.hasNoError">
      <p class="hotelservice__welcome--subtitle">
        {{ 'hotelservice.pickupTitle' | translate }}
      </p>
    </ng-container>

    <div class="hotelservice__module_container">
      <div class="hotelservice__day-selector" style="position: relative">
        <i
          class="fa-regular fa-calendar"
          style="
            position: absolute;
            top: 15px;
            left: 16px;
            font-size: 24px;
            z-index: 1;
          "
        ></i>
        <select
          class="daySelector"
          [ngModel]="dateSelected"
          (ngModelChange)="onDateChange($event)"
        >
          <option value="" disabled selected hidden>
            {{ 'global.selectDay' | translate }}
          </option>
          <option *ngFor="let date of datesToSelect" [value]="date">
            {{ getDateLabel(date) }}
          </option>
        </select>
        <i
          class="fa fa-caret-down"
          style="
            position: absolute;
            top: 16px;
            right: 16px;
            font-size: 19px;
            z-index: 1;
          "
        ></i>
      </div>
      <div class="hotelservice__day-selector" style="position: relative">
        <i
          class="fa-regular fa-clock"
          style="
            position: absolute;
            top: 15px;
            left: 16px;
            font-size: 24px;
            z-index: 0;
          "
        ></i>
        <select
          id="timeSelector"
          class="timeSelector"
          [ngModel]="timeSelected"
          (ngModelChange)="onTimeChange($event)"
        >
          <option value="" disabled selected hidden>
            {{ 'takeaway.selectTime' | translate }}
          </option>
          <option *ngFor="let time of timesToSelect" [value]="time">
            {{ time }}
          </option>
        </select>
        <i
          class="fa fa-caret-down"
          style="
            position: absolute;
            top: 16px;
            right: 16px;
            font-size: 19px;
            z-index: 0;
          "
        ></i>
      </div>
      <button
        class="hotelservice__proceed-button"
        (click)="goOrder()"
        [disabled]="!(dateSelected && timeSelected)"
      >
        {{ 'global.orderButton' | translate }}
      </button>
      <small>{{ 'hotelservice.pickupText' | translate }}</small>
    </div>
  </div>
</ng-container>

<ng-template #loader>
  <p class="home__placeholder">{{ 'global.loaderText' | translate }}</p>
  <loader-animation></loader-animation>
</ng-template>

<ng-template #closed>
  <p class="hotelservice__placeholder">
    {{ (businessFacade.business$.data$ | async)?.name }} -
    {{ 'global.closedText' | translate }}
  </p>
</ng-template>

<ng-template #error>
  <p class="hotelservice__placeholder">
    {{ businessFacade.business$.error$ | async }}
  </p>
</ng-template>
