import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'tip-percentage',
  templateUrl: 'tip-percentage.component.html',
  styleUrls: ['tip-percentage.component.scss'],
})
export class TipPercentageComponent {
  selectedValue = 15;
  @Output() percentageChange = new EventEmitter<number>();

  setPercentage(value: number) {
    this.selectedValue = value;
    this.percentageChange.emit(value);
  }
}
