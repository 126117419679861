<div class="shopping-container">
  <div class="shopping__header">
    <div
      *ngIf="!payFromQrCode && !isPayTeaser"
      class="shopping__header--icon"
      (click)="goBack()"
    >
      <i class="far fa-chevron-left"></i>
    </div>
    <p *ngIf="!isPayTeaser" class="shopping__header--title">
      {{ 'shoppingcart.title' | translate }}
    </p>
    <p *ngIf="isPayTeaser" class="shopping__header--title">
      {{ 'inHouseOpenTable.paymentSummaryTitle' | translate }}
    </p>
  </div>
  <div class="shopping__item" *ngFor="let product of cartProducts; index as i">
    <div class="shopping__item--header">
      <p *ngIf="!isPayTeaser" class="shopping__item--title">
        {{ product?.name }}
        <span *ngIf="product.variant">
          {{
            product.variant.name === 'BASE_VARIANT'
              ? ''
              : '- ' + product.variant.name
          }}</span
        >
      </p>
      <p *ngIf="isPayTeaser" class="shopping__item--title">
        {{ product.quantity }} x {{ product.name }}
        <span *ngIf="product.variant">
          {{
            product.variant.name === 'BASE_VARIANT'
              ? ''
              : '- ' + product.variant.name
          }}
        </span>
      </p>
      <p class="shopping__item--price">
        CHF {{ getProductPrice(i) | number: '1.2-2' }}
      </p>
    </div>
    <div class="shopping__item--body">
      <p *ngIf="false" class="shopping__item--body-description">
        {{ product.description }}
      </p>
      <div class="mt-5" *ngIf="product.extras.length > 0 || false">
        <div *ngFor="let extra of product.extras || []">
          <p *ngFor="let option of extra.options || []" class="list_item">
            x {{ option.quantity }} - {{ option.name }}
          </p>
        </div>
      </div>
      <div
        class="shopping__item--footer"
        *ngIf="!payFromQrCode && !isPayTeaser"
      >
        <input-counter
          [isDisabled]="payFromQrCode"
          [counter]="product.quantity"
          (handleDecrease)="onCounterChange(false, i)"
          (handleIncrease)="onCounterChange(true, i)"
        ></input-counter>
        <div
          *ngIf="!payFromQrCode"
          class="shopping__item--footer-trash"
          (click)="removeProduct(i)"
        >
          <i class="far fa-trash"></i>
        </div>
      </div>
    </div>

    <div class="shopping__item empty-cart" *ngIf="cartProducts.length === 0">
      <p class="shopping__header--title">
        {{ 'shoppingcart.emptyText' | translate }}
      </p>
    </div>

    <div *ngIf="!isMinimumOrderValueReached()" class="alert-wrapper">
      <div class="alert-info" role="alert">
        <div [innerHTML]="getTranslatedMessage()"></div>
      </div>
    </div>

    <div class="bottom-button-container" *ngIf="isMinimumOrderValueReached()">
      <div
        *ngIf="serviceTypeModule === activatedModule.Delivery"
        class="order-summary"
      >
        <div class="summary-row">
          <div class="row-label">
            {{ 'tipspage.subtotalLabel' | translate }}
          </div>
          <div class="row-value">CHF {{ getTotalPriceFn() }}</div>
        </div>
        <div class="summary-row">
          <div class="row-label">
            {{ 'shoppingcart.deliveryCostLabel' | translate }}
          </div>
          <div class="row-value">CHF {{ deliveryCost }}</div>
        </div>
      </div>
      <button (click)="onContinue()" *ngIf="cartProducts.length > 0">
        <span
          *ngIf="
            businessFacade.orderResponse$.isLoading$ ||
              businessFacade.businessConfigurations$.isLoading$ | async
          "
          class="loader"
        ></span>
        <span
          *ngIf="
            (businessFacade.orderResponse$.isNotLoading$ &&
              businessFacade.businessConfigurations$.isNotLoading$ | async) &&
            !skipGuestInformation
          "
        >
          {{ 'global.checkout' | translate }} - CHF
          {{ getTotalPrice | number: '1.2-2' }}
        </span>
        <span *ngIf="skipGuestInformation">
          {{ 'global.orderButton' | translate }} - CHF
          {{ getTotalPrice | number: '1.2-2' }}
        </span>
      </button>

      <button
        (click)="goHome()"
        *ngIf="cartProducts.length === 0 && !payFromQrCode"
      >
        {{ 'global.homeBackButton' | translate }}
      </button>
    </div>
  </div>
</div>
