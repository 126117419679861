<!-- <h2 mat-dialog-title>{{ data.title }}</h2>
<mat-dialog-content>
  <p>{{ data.message }}</p>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="close()">Close</button>
</mat-dialog-actions> -->

<div class="container">
  <div style="display: flex; justify-content: flex-end">
    <div class="close-modal" (click)="close()">
      <i class="far fa-times"></i>
    </div>
  </div>
  <div class="content">
    <i class="fad fa-exclamation-circle warning-icon"></i>
    <p>
      {{ 'sessionmodal.title' | translate }}
    </p>
    <button (click)="onContinue()">
      {{ 'sessionmodal.buttonText' | translate }}
    </button>
  </div>
</div>
