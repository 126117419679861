<div class="container">
  <p class="container__title" mat-dialog-title>
    {{ 'paymentmethod.title' | translate }}
  </p>
  <hr />
  <div mat-dialog-content style="padding-bottom: 15px">
    <button *ngIf="!data" (click)="closeDialog(1)" class="container__content">
      {{ 'paymentmethod.cash' | translate }}
    </button>
    <hr />
    <button (click)="closeDialog(0)" class="container__content">
      {{ 'paymentmethod.online' | translate }}
    </button>
  </div>
</div>
