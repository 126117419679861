import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
  ViewChild,
} from '@angular/core';

import { ViewportScroller } from '@angular/common';
import { ProductCategory } from '@orderapp/api-clients/orderadmin-api-client';

@Component({
  selector: 'orderapp-frontend-menu-categories',
  templateUrl: './menu-categories.component.html',
  styleUrls: ['./menu-categories.component.scss'],
})
export class MenuCategoriesComponent implements AfterViewInit {
  isSticky = false;
  anchorValue = '';
  @Input() categories: ProductCategory[] = [];
  @ViewChild('header') header!: ElementRef;

  constructor(
    private viewportScroller: ViewportScroller,
    private renderer: Renderer2,
  ) {}

  ngAfterViewInit(): void {
    this.getDefaultSection();
  }

  @HostListener('window:scroll')
  checkScroll() {
    this.isSticky = window.pageYOffset >= 75;
    this.anchorValue = this.getCurrentSection();
    this.scrollHeaderToActiveItem();
  }

  getCurrentSection(): string {
    const sections = document.querySelectorAll('section');
    let currentSection: string | null = null;

    sections.forEach((section) => {
      const sectionTop = section.getBoundingClientRect().top;
      if (sectionTop <= 80) {
        currentSection = section.getAttribute('id');
      }
    });

    return currentSection || this.getDefaultSection();
  }

  scrollHeaderToActiveItem() {
    const activeItems = document.getElementsByClassName('active');

    if (activeItems.length > 0) {
      const activeItem = activeItems[0] as HTMLElement;

      const container = document.getElementById('sub-header') as HTMLElement;

      const nextActiveItem = activeItem.nextElementSibling as HTMLElement;

      if (nextActiveItem) {
        const scrollAmount =
          nextActiveItem.offsetLeft -
          container.offsetWidth / 2 +
          nextActiveItem.offsetWidth / 2;

        container.scrollTo({
          left: scrollAmount,
        });
      }
    }
  }
  getDefaultSection(): string {
    const sections = document.querySelectorAll('section');
    const defaultSection: string | null = sections[0]?.getAttribute('id');

    return defaultSection || '';
  }

  isItemActive(itemName: string, i: number): boolean {
    return (
      this.anchorValue === itemName.toLowerCase() ||
      (this.anchorValue === '' && i === 0 && !this.isSticky)
    );
  }

  scrollTo(section: string) {
    this.anchorValue = section;
    this.viewportScroller.scrollToAnchor(this.anchorValue);
  }
}
