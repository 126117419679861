<div class="tips-container">
  <div class="tips__header--icon" (click)="goBack()">
    <i class="far fa-chevron-left"></i>
  </div>
  <p class="tips__header--title">
    {{ 'tipspage.title' | translate }}
  </p>
  <p class="tips__header--text">
    {{ 'tipspage.text' | translate }}
  </p>

  <div style="padding: 15px">
    <mat-button-toggle-group
      name="fontStyle"
      [value]="selectedTip"
      aria-label="Font Style"
      (change)="onChangeTips($event)"
    >
      <mat-button-toggle [value]="tipsType.NO_TIP">{{
        'tipspage.noTipTab' | translate
      }}</mat-button-toggle>
      <mat-button-toggle [value]="tipsType.AUTO">{{
        'tipspage.autoTab' | translate
      }}</mat-button-toggle>
      <mat-button-toggle [value]="tipsType.PERCENTAGE">%</mat-button-toggle>
      <mat-button-toggle [value]="tipsType.CUSTOM">CHF</mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <div class="tips-container--body">
    <div
      *ngIf="selectedTip === tipsType.PERCENTAGE"
      class="tips-container--body-center"
    >
      <tip-percentage (percentageChange)="onPercentageChange($event)"></tip-percentage>
    </div>
    <div
      *ngIf="selectedTip === tipsType.CUSTOM"
      class="tips-container--body-center"
    >
      <mat-form-field class="" appearance="outline">
        <mat-label>CHF</mat-label>
        <input
          matInput
          placeholder="3.15"
          [(ngModel)]="customTipAmount"
          (change)="calculateTip()"
          type="number"
        />
      </mat-form-field>
    </div>
    <p
      [class]="
        selectedTip === tipsType.AUTO || selectedTip === tipsType.NO_TIP
          ? 'minHeight'
          : ''
      "
    >
      {{ 'tipspage.tipAmountLabel' | translate }}
      <b>{{ roundAmount(totalTipValue) | number: '1.2-2' }} CHF</b>
    </p>
  </div>

  <div class="tips-container--footer">
    <div class="tips-container--footer-text">
      <p>{{ 'tipspage.subtotalLabel' | translate }}</p>
      <p>CHF {{ getTotalPrice - roundAmount(totalTipValue) | number: '1.2-2' }}</p>
    </div>
    <div class="tips-container--footer-text">
      <p>{{ 'tipspage.tipAmountLabel' | translate }}</p>
      <p>CHF {{ roundAmount(totalTipValue) | number: '1.2-2' }}</p>
    </div>
    <div class="tips-container--footer-text">
      <p>{{ 'tipspage.totalLabel' | translate }}</p>
      <p>CHF {{ getTotalPrice | number: '1.2-2' }}</p>
    </div>
  </div>
</div>

<div class="bottom-button-container">
  <button (click)="onContinue()" [disabled]="isLoading">
    <span *ngIf="!isLoading">
      {{ 'global.orderButton' | translate }}
      {{ getTotalPrice | number: '1.2-2' }} - CHF
    </span>
    <jamatu-loader *ngIf="isLoading"></jamatu-loader>
  </button>
</div>

