<div class="activated-module" *ngIf="serviceType && serviceType.isEnabled">
  <div class="activated-module__icon">
    <span [class]="ServiceTypeUtil.icon(serviceType)"></span>
  </div>
  <div class="activated-module__text">
    <p class="activated-module__text--title">{{ title }}</p>
    <p class="activated-module__text--description">
      {{ description }}
    </p>
  </div>
  <div class="activated-module__action">
    <span class="fa-duotone fa-duotone-action fa-chevron-right"></span>
  </div>
</div>
