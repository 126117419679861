<div class="activated-module">
  <div class="activated-module__icon">
    <span class="fa-duotone fa-duotone-primary fa fa-credit-card"></span>
  </div>
  <div class="activated-module__text">
    <p class="activated-module__text--title">
      {{ 'inHouseOpenTable.payTeaserTitle' | translate }}
    </p>
    <p class="activated-module__text--description">
      {{ 'inHouseOpenTable.payTeaserText' | translate }}
    </p>
  </div>
  <div class="activated-module__action">
    <span class="fa-duotone fa-duotone-action fa-chevron-right"></span>
  </div>
</div>
