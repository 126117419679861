<ng-container>
  <div class="not-found">
    <div class="not-found__image">
      <img
        class="header__logo"
        [src]="'../../assets/images/404.svg'"
        alt="order app logo"
      />
    </div>
    <div class="not-found__title">
      {{ 'notfound.pageNotFoundTitle' | translate }}
    </div>
    <div class="not-found__subtitle">
      {{ 'notfound.pageNotFoundText' | translate }}
    </div>
    <div class="not-found__back">
      <button (click)="goBack()">{{ 'global.backButton' | translate }}</button>
    </div>
  </div>
</ng-container>
