<div>
  <div class="title-container">
    <p *ngIf="menusOptions?.length == 1" class="title">
      {{ menuProducts.menuName }}
    </p>
    <mat-select
      *ngIf="menusOptions && menusOptions && menusOptions.length > 1"
      class="custom-select"
      [(ngModel)]="selectedMenuId"
      (selectionChange)="onSelectedMenuChange()"
    >
      <mat-option
        *ngFor="let menu of menusOptions"
        [value]="menu.menuId"
        class="custom-option"
      >
        {{ menu.menuName }}
      </mat-option>
    </mat-select>
  </div>
  <orderapp-frontend-menu-categories
    [categories]="menuProducts.foodProducts"
  ></orderapp-frontend-menu-categories>

  <div style="padding-bottom: 80px">
    <div *ngFor="let category of menuProducts.foodProducts; let i = index">
      <orderapp-frontend-menu-items
        [foodTypeId]="1"
        [hasBeverageProducts]="menuProducts.beverageProducts.length > 0"
        [category]="category"
        [properties]="menuProducts.properties"
        [showBottomButton]="i === 0"
        [menuId]="selectedMenuId"
      ></orderapp-frontend-menu-items>
    </div>
  </div>
</div>
