<div [ngClass]="{ sticky: isSticky }" class="header-category" #header>
  <div class="categories__content" id="sub-header">
    <ng-container *ngFor="let item of categories; let i = index">
      <div
        class="categories__content--center"
        (click)="scrollTo(item.name.toLowerCase())"
      >
        <div
          [ngClass]="{
            'categories__content--items': true,
            active: isItemActive(item.name.toLowerCase(), i)
          }"
        >
          <p [ngClass]="{ active: anchorValue === item.name.toLowerCase() }">
            <i
              [class]="item.icon"
              style="font-size: 24px"
            ></i>
          </p>
        </div>
        <p
          [ngClass]="{
            'categories__content--items-label': true,
            active: isItemActive(item.name.toLowerCase(), i) && isSticky
          }"
        >
          {{ item.name }}
        </p>
      </div>
    </ng-container>
  </div>
</div>
