<mat-button-toggle-group
  name="fontStyle"
  aria-label="Font Style"
  [value]="selectedValue"
  (change)="setPercentage($event.value)"
>
  <mat-button-toggle [value]="5">5%</mat-button-toggle>
  <mat-button-toggle [value]="10">10%</mat-button-toggle>
  <mat-button-toggle [value]="15">15%</mat-button-toggle>
  <mat-button-toggle [value]="20">20%</mat-button-toggle>
  <mat-button-toggle [value]="25">25%</mat-button-toggle>
</mat-button-toggle-group>
