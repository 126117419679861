import { Component, OnDestroy, OnInit } from '@angular/core';
import { BusinessFacade } from '../../core/facades/business.facade';
import { combineLatestWith, Subject, takeUntil } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { BusinessStore } from '../../core/models/business-store.model';
import * as moment from 'moment';
import 'moment/locale/de';
import { FormatBusinessName } from '../../shared/utility/format-title';
import { serviceTypes } from '../../core/constants/service-type';
import { ActivatedModule } from '../../core/enums/activated-module.enum';
import { QrcodeService } from '../../core/services/qrcode.service';
import {
  Duration,
  OrderingTimeResponse,
  PreparationTimeResponse,
} from '@orderapp/api-clients/orderadmin-api-client';
import { SessionStorageKeys } from '../../core/constants/session-storage-keys';

@Component({
  selector: 'orderapp-frontend-hotel-service',
  templateUrl: './hotel-service.component.html',
  styleUrls: ['./hotel-service.component.scss'],
})
export class HotelServiceComponent implements OnInit, OnDestroy {
  private readonly _destroy$ = new Subject();
  moment = moment;
  datesToSelect: string[] = [];
  timesToSelect: string[] = [];

  menuId = 0;

  dateSelected = '';
  timeSelected = '';

  orderingTimes: OrderingTimeResponse[] = [];
  preparationTimes: PreparationTimeResponse[] = [];

  constructor(
    public readonly businessFacade: BusinessFacade,
    private _router: Router,
    private readonly _route: ActivatedRoute,
    public readonly _qrCodeService: QrcodeService,
  ) {
    this._route.params.pipe(takeUntil(this._destroy$)).subscribe({
      next: (params) => {
        const businessStore = params as BusinessStore;
        this.getQrCodeDetails(Number(businessStore.qrCodeId), businessStore);
        this.businessFacade.setBusinessStore(businessStore);
        this.businessFacade.fetchOrderingTimes(
          Number(businessStore.businessId),
          serviceTypes['Hotel Service'].name,
        );
        this.businessFacade.fetchPreparationTimes(
          Number(businessStore.businessId),
          serviceTypes['Hotel Service'].name,
        );

        document.title = FormatBusinessName(
          businessStore.businessName,
          'Abholzeiten',
        );
      },
    });
  }

  getQrCodeDetails(qrCodeId: number, businessStore: BusinessStore) {
    this._qrCodeService.getQrcodeDetails(qrCodeId).subscribe((result) => {
      this.businessFacade.fetchMenuProducts(
        Number(businessStore.businessId),
        serviceTypes['Hotel Service'].name,
        qrCodeId,
      );
    });
    this._qrCodeService.getQrcodeDetails(qrCodeId).subscribe((result) => {
      this.businessFacade.fetchMenuProducts(
        Number(businessStore.businessId),
        serviceTypes['Hotel Service'].name,
        qrCodeId,
      );
    });
  }

  ngOnInit(): void {
    this.businessFacade.orderingTimes$.data$
      .pipe(combineLatestWith(this.businessFacade.preparationTimes$.data$))
      .subscribe(([orderingTimes, preparationTimes]) => {
        if (orderingTimes.length) {
          this.orderingTimes = orderingTimes.filter((x) => x.isOpen);
          this.preparationTimes = preparationTimes;
          this.calculateDatesToShow();
        }
      });

    this.businessFacade.serviceTypes$.data$.subscribe((serviceTypes) => {
      if (
        serviceTypes &&
        serviceTypes.findIndex(
          (x) => x.name === ActivatedModule.HotelService && x.isEnabled,
        ) < 0
      ) {
        this.businessFacade.setBusinessIsClosed(true);
        this._router.navigate([
          decodeURIComponent(this._router.url).replace('/pick-up', ''),
        ]);
        this._router.navigate([
          decodeURIComponent(this._router.url).replace('/pick-up', ''),
        ]);
      }
    });

    this.businessFacade.menuProducts$.data$.subscribe((menuProducts) => {
      if (menuProducts?.menus?.length === 0) {
        this.businessFacade.setBusinessIsClosed(true);
        this._router.navigate([
          decodeURIComponent(this._router.url).replace('/pick-up', ''),
        ]);
      }
    });
  }

  public getDateLabel(date: string): string {
    const momentDate = moment(date);
    if (moment().isSame(momentDate, 'day')) return 'Heute';

    return momentDate.format('dd - DD.MM.YYYY');
  }

  onDateChange(event: string): void {
    this.dateSelected = event;
    this.timesToSelect = [];
    this.calculateTimesToShow();
  }

  goToClosedBusinessScreen(): void {
    this.businessFacade.setBusinessIsClosed(true);
    this._router.navigate([
      decodeURIComponent(this._router.url).replace('/pick-up', ''),
    ]);
    this._router.navigate([
      decodeURIComponent(this._router.url).replace('/pick-up', ''),
    ]);
  }

  calculateDatesToShow(): void {
    const date = moment();
    this.datesToSelect = [];
    if (!this.orderingTimes.length) {
      this.goToClosedBusinessScreen();
      return;
    }

    while (this.datesToSelect.length < 5) {
      const dayNumber = date.isoWeekday();
      const indexOfOrderingDate = this.orderingTimes.findIndex(
        (x) => x.dayId === dayNumber,
      );
      if (indexOfOrderingDate >= 0) {
        this.datesToSelect.push(date.format('YYYYMMDD'));
      }

      date.add(1, 'days');
    }
    this.dateSelected = this.datesToSelect[0];
    this.calculateTimesToShow();
    if (this.timesToSelect.length == 0) {
      if (this.datesToSelect[1]) {
        this.dateSelected = this.datesToSelect[1];
        this.datesToSelect.shift();
        this.calculateTimesToShow();
      } else {
        this.goToClosedBusinessScreen();
      }
    }
  }
  calculateTimesToShow(): void {
    const dateSelected = moment(this.dateSelected);
    const orderingTimesForDay = this.orderingTimes.find(
      (x) => x.dayId === dateSelected.isoWeekday(),
    );

    const durations: Duration[] = orderingTimesForDay?.durations ?? [];
    const preparationTime = this.preparationTimes.length
      ? this.preparationTimes[0].minutes
      : 30;
    this.timesToSelect = [];
    const now = moment();

    durations.forEach((duration) => {
      if (duration.opens == '24h') {
        duration.opens = '00:00';
        duration.closes = '23:59';
      }
      const opensTimeComponents = duration.opens.split(':');
      const closesTimeComponents = duration.closes.split(':');
      const opensTimeDate = moment(this.dateSelected)
        .set('hour', Number(opensTimeComponents[0]))
        .set('minute', Number(opensTimeComponents[1]));
      const closesTimeDate = moment(this.dateSelected)
        .set('hour', Number(closesTimeComponents[0]))
        .set('minute', Number(closesTimeComponents[1]));

      if (moment().isSame(opensTimeDate, 'day')) {
        if (now.diff(opensTimeDate) >= 0) {
          opensTimeDate.set('hours', now.get('hours'));
          opensTimeDate.set('minutes', Math.ceil(now.get('minutes') / 15) * 15);
          opensTimeDate.add(preparationTime, 'minutes');
        }
      }

      while (opensTimeDate.diff(closesTimeDate) <= 0) {
        if (
          this.timesToSelect.findIndex(
            (x) => x === opensTimeDate.format('HH:mm'),
          ) < 0
        )
          this.timesToSelect.push(`${opensTimeDate.format('HH:mm')}`);
        opensTimeDate.add(preparationTime, 'minutes');
      }
    });

    this.timesToSelect.sort();

    if (this.timesToSelect.length) {
      this.timeSelected = this.timesToSelect[0];
    }
  }

  onTimeChange(event: string): void {
    this.timeSelected = event;
  }

  goOrder(): void {
    if (!(this.dateSelected && this.timeSelected)) {
      return;
    }

    sessionStorage.setItem(
      'pickUpTime',
      `${moment(this.dateSelected).format('YYYY-MM-DD')}T${this.timeSelected}`,
    );

    this.businessFacade.menuProducts$.data$.subscribe((menuProducts) => {
      if (menuProducts?.menus?.length == 1) {
        this.businessFacade.setSelectedMenuId(
          menuProducts?.menus[0].menuId ?? 0,
        );
        sessionStorage.setItem(
          SessionStorageKeys.MenuId,
          (menuProducts?.menus[0].menuId ?? 0).toString(),
        );
        if (menuProducts?.menus[0].foodProducts.length > 0) {
          this.businessFacade.goToHotelServiceUrl('food-menu');
        } else {
          this.businessFacade.goToHotelServiceUrl('beverage-menu');
        }
      }
      if (menuProducts?.menus && menuProducts.menus.length > 1) {
        this.businessFacade.goToHotelServiceUrl('menu-selection');
      }
      if (menuProducts?.menus?.length == 0) {
        this.businessFacade.setBusinessIsClosed(true);
        this._router.navigate([
          decodeURIComponent(this._router.url).replace('/pick-up', ''),
        ]);
      }
    });
  }

  public ngOnDestroy(): void {
    this._destroy$.next(null);
    this._destroy$.complete();
  }
}
