import {
  AfterViewInit,
  Component,
  HostBinding,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { SessionService } from './core/services/session.service';
import { BusinessFacade } from './core/facades/business.facade';
import { routeTransitionAnimations } from './route-transition-animations';
import { SessionModalComponent } from './shared/components/session-modal/session-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { filter, Subject, takeUntil } from 'rxjs';
import { environment } from '../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from './core/services/translate.service';
import { fullSessionTracker } from 'fullsession'

declare let gtag: any;

@Component({
  selector: 'orderapp-frontend-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeTransitionAnimations],
})
export class AppComponent implements AfterViewInit, OnDestroy, OnInit {
  @HostBinding('style.--primary-color') primaryColor = '';
  @HostBinding('style.--primary-color-background') primaryColorBackground = '';
  @HostBinding('style.--primary-color-box-shadow') primaryColorBoxShadow = '';
  countdownTimer = 180;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private _businessFacade: BusinessFacade,
    private sessionService: SessionService,
    public dialog: MatDialog,
    private _translate: TranslateService,
    private translationService: TranslationService,
    private router: Router,
  ) {
    // localStorage.removeItem('pickUpTime');
    this._businessFacade.cart.subscribe((data) => {
      if (data.length !== 0) {
        this.resetSessionTimer();
      }
      // this.cartProducts = data;
    });
    this.init();
    const navEndEvents = router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
    );
    navEndEvents.subscribe((event: any) => {
      gtag('js', new Date());
      gtag('config', environment.GOOLGLE_TAG_KEY, {
        page_path: event.urlAfterRedirects,
      });
    });
  }

  init() {
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${environment.GOOLGLE_TAG_KEY}`;
    script.async = true;
    document.getElementsByTagName('head')[0].appendChild(script);

    const gtagEl = document.createElement('script');
    const gtagBody = document.createTextNode(`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
    `);
    gtagEl.appendChild(gtagBody);
    document.body.appendChild(gtagEl);
  }

  hexToRgb(hex: string, opactiy: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result) {
      const r = parseInt(result[1], 16);
      const g = parseInt(result[2], 16);
      const b = parseInt(result[3], 16);
      const a = parseFloat(opactiy);
      return `rgba(${r},${g},${b},${a})`;
    }
    return `rgba(255, 102, 0, ${opactiy});`;
  }

  public openDialog() {
    const dialogRef = this.dialog.open(SessionModalComponent, {
      minWidth: 340,
      minHeight: 300,
      maxWidth: 340,
      maxHeight: 300,
    });
  }

  /**
   * Page transition
   */
  prepareRoute(outlet: RouterOutlet) {
    return (
      outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData['animationState']
    );
  }

  /**
   * Host Listener if user is not active
   */
  @HostListener('document:click')
  resetSessionTimer() {
    this._businessFacade.cart.subscribe((data) => {
      if (data.length !== 0) {
        this.sessionService.resetWaringTimer();
        this.sessionService.startInactivityTimer(() => {
          this.sessionService.startWarningTimer(() => {
            this.sessionService.startCountDownInterval();
            this.openDialog();
          });
        });
      }
      // this.cartProducts = data;
    });
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    // Check for your desired hotkey combination
    if (event.ctrlKey && event.key === 't') {
      const currentLanguage = this._translate.currentLang;
      const newLanguage = currentLanguage === 'en' ? 'de' : 'en';
      this._translate.use(newLanguage);
    }
  }

  ngAfterViewInit(): void {
    this._businessFacade.business$.data$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        setTimeout(() => {
          const primaryColor = res?.primaryColor || '#ff6600';
          const primaryColorBackground = this.hexToRgb(primaryColor, '0.08');
          const primaryColorBoxShadow = this.hexToRgb(primaryColor, '0.1');

          this.primaryColor = primaryColor;
          this.primaryColorBackground = primaryColorBackground;
          this.primaryColorBoxShadow = primaryColorBoxShadow;

          sessionStorage.setItem('primaryColor', primaryColor);
          sessionStorage.setItem(
            'primaryColorBackground',
            primaryColorBackground,
          );
          sessionStorage.setItem(
            'primaryColorBoxShadow',
            primaryColorBoxShadow,
          );
        });
      });
  }

  ngOnInit(): void {
    fullSessionTracker.initialize('cus_m3irwwbhjik');
    this.translationService.initializeTranslateService();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
