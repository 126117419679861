import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'apps/orderapp/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OpenTableService {
  constructor(private _http: HttpClient) {}

  markAsPaid(businessId: string, openTableId: string): Observable<any> {
    return this._http.get<any>(
      `${environment.API_URL}/Orders/${businessId}/${openTableId}/table-paid`,
    );
  }
}
