import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PaymentMethod } from '../enums/payment-method.enum';
import {
  JamPayClient,
  JamPayGatewayRequest,
  JamPayGatewayResponse,
  PaymentMethodSetting,
  PaymentMethodSettingClient,
} from '@orderapp/api-clients/orderadmin-api-client';

@Injectable({ providedIn: 'root' })
export class PaymentService {
  constructor(
    private readonly _http: HttpClient,
    private readonly _jamPayClient: JamPayClient,
    private readonly _paymentMethodSettingClient: PaymentMethodSettingClient,
  ) {}

  public createPaymentGateway(
    businessId: string,
    orderNumber: string,
    successRedirectUrl: string,
    failedRedirectUrl: string,
    cancelRedirectUrl: string,
    openTableId: string | undefined,
  ): Observable<JamPayGatewayResponse> {
    const gatewayRequest = new JamPayGatewayRequest({
      businessId: Number(businessId),
      successRedirectUrl: successRedirectUrl,
      failedRedirectUrl: failedRedirectUrl,
      cancelRedirectUrl: cancelRedirectUrl,
      openTableId: openTableId,
      orderNumber: orderNumber,
    });
    return this._jamPayClient.createPaymentGateway(gatewayRequest);
  }

  public getPaymentMethod(
    businessId: number,
    serviceType: string,
    paymentMethod: PaymentMethod,
  ): Observable<PaymentMethodSetting> {
    return this._paymentMethodSettingClient.get2(
      businessId,
      serviceType,
      paymentMethod,
    );
  }
}
