import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filter, Observable, take } from 'rxjs';
import { CartProduct } from '../models/cart.model';
import { BusinessFacade } from '../facades/business.facade';
import { PaymentService } from './payment.service';
import { ActivatedModule } from '../enums/activated-module.enum';
import { serviceTypes } from '../constants/service-type';
import {
  FileResponse,
  GetOpenTableOrdersResponseWithExtras,
  OrderRequest,
  OrdersClient,
} from '@orderapp/api-clients/orderadmin-api-client';

@Injectable({ providedIn: 'root' })
export class OrdersService {
  constructor(
    private _http: HttpClient,
    private businessFacade: BusinessFacade,
    private paymentService: PaymentService,
    private _ordersClient: OrdersClient,
  ) {}

  public downloadInvoice(orderId: number): Observable<FileResponse> {
    return this._ordersClient.generateInvoice(orderId);
  }

  public downloadInvoiceByOpenTableId(
    openTableId: string,
  ): Observable<FileResponse> {
    return this._ordersClient.generateInvoiceByOpenTableId(openTableId);
  }

  public getOrderByOpenTableId(
    openTableId: string,
  ): Observable<GetOpenTableOrdersResponseWithExtras> {
    return this._ordersClient.getOrdersByOpenTableId(openTableId);
  }

  public addTipsToOrdersByOpenTableId(
    openTableId: string,
    tipsAmount: number,
  ): Observable<void> {
    return this._ordersClient.addTipsToOrdersByOpenTableId(
      openTableId,
      tipsAmount,
    );
  }

  createOrder(
    businessId: number,
    serviceTypeModule: ActivatedModule,
    cartProducts: CartProduct[],
    availableTime: string | null,
    deliveryCost: number,
    additionalData?: any,
  ): OrderRequest {
    return {
      businessId: businessId,
      serviceTypeName: serviceTypes[serviceTypeModule].name,
      readyForPickupTime: availableTime + ':00',
      orderItems: cartProducts.map((cartProduct) => ({
        menuId: cartProduct.menuId,
        productId: cartProduct.productId,
        variantId: cartProduct.variant?.variantId || null,
        productQuantity: cartProduct.quantity,
        extras: cartProduct.extras?.map((cartExtra: any) => ({
          extraId: cartExtra.extraId,
          productId: cartExtra.productId,
          extraName: cartExtra.name,
          selectionType: cartExtra.selectionType,
          options: cartExtra.options,
        })),
        comment: cartProduct.comment,
      })),
      totalPrice: this.getTotalPriceFn(cartProducts) + deliveryCost,
      tipAmount: 0,
      qrCodeId: additionalData?.qrCodeId || null,
      paymentMethod: 0,
      ...additionalData,
    };
  }

  processOrder(
    order: OrderRequest,
    businessId: number,
    serviceTypeModule: ActivatedModule,
    isInhouse: boolean,
  ) {
    this.businessFacade
      .putOrder(order)
      .then(() => {
        this.businessFacade.orderResponse$.data$
          .pipe(
            filter((response) => response && !isInhouse),
            take(1),
          )
          .subscribe((response_1) => {
            const payrexxGateway = this.paymentService.createPaymentGateway(
              String(businessId),
              response_1.orderNumber,
              this.businessFacade.generatePaymentUrlsForServiceType(
                'success',
                serviceTypeModule,
              ),
              this.businessFacade.generatePaymentUrlsForServiceType(
                'failed',
                serviceTypeModule,
              ),
              this.businessFacade.generatePaymentUrlsForServiceType(
                'cancel',
                serviceTypeModule,
              ),
              undefined,
            );
            this.businessFacade.saveAppState();
            payrexxGateway.subscribe((gatewayResponse) =>
              this.redirectToPaymentGateway(
                gatewayResponse.link ? gatewayResponse.link : '',
              ),
            );
          });
      })
      .catch((error) => {
        const errorMessage = JSON.stringify(
          error,
          Object.getOwnPropertyNames(error),
        );
        throw new Error(errorMessage);
      });
  }

  getTotalPriceFn(cartProducts: any[]): number {
    let totalPrice = 0;
    cartProducts.forEach((cartProduct) => {
      let productTotalPrice = 0;
      if (cartProduct.variant) {
        productTotalPrice += cartProduct.quantity * cartProduct.variant.price;
      } else {
        productTotalPrice += cartProduct.quantity * cartProduct.price;
      }
      cartProduct.extras.forEach((extra: any) => {
        productTotalPrice += extra.options.reduce(
          (
            priceSummed: number,
            extraOption: { price: number; quantity: number },
          ) => {
            return (
              priceSummed +
              extraOption.price * extraOption.quantity * cartProduct.quantity
            );
          },
          0,
        );
      });
      productTotalPrice = Math.round(productTotalPrice * 100) / 100;
      totalPrice += productTotalPrice;
    });
    return totalPrice;
  }

  private redirectToPaymentGateway(paymentLink: string): void {
    window.location.href = paymentLink;
  }
}
