<div class="activated-module">
  <div class="activated-module__icon">
    <span class="fa-duotone fa-duotone-primary fa-solid fa-square-list"></span>
  </div>
  <div class="activated-module__text">
    <div class="menuName-centered">
      <p class="activated-module__text--title">
        {{ menuName }}
      </p>
    </div>
  </div>
  <div class="activated-module__action">
    <span class="fa-duotone fa-duotone-action fa-chevron-right"></span>
  </div>
</div>
