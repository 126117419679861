import { Component, ElementRef, OnInit } from '@angular/core';

@Component({
  selector: 'loader-animation',
  templateUrl: 'loader-animation.component.html',
  styleUrls: ['loader-animation.component.scss'],
})
export class LoaderAnimationComponent implements OnInit {
  private animation: any;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    // this.loadLottieAnimation();
  }

  private loadLottieAnimation() {
    const container = this.el.nativeElement.querySelector('.lottie-container');

    // eslint-disable-next-line @typescript-eslint/no-var-requires

    /*
    this.animation = lottie.loadAnimation({
      container: container,
      renderer: 'svg', // You can use 'canvas' or 'html' depending on your preference and support
      loop: true,
      autoplay: true,
      animationData: animationData,
    });
     */
  }
}
