import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { BusinessService } from '../services/business.service';
import { StateData } from '../models/state-data.model';
import {
  BehaviorSubject,
  catchError,
  distinctUntilChanged,
  finalize,
  of,
  take,
} from 'rxjs';
import { BusinessStore } from '../models/business-store.model';
import { ActivatedModule } from '../enums/activated-module.enum';
import { BusinessConfigurationService } from '../services/business-configuration.service';
import {
  BusinessConfiguration,
  BusinessServiceTypeResponseModel,
  DeliveryZone,
  GetBusinessResponse,
  MenuProductsResponseModel,
  MenusWithProductsResponseModel,
  MenuWithProductsResponseModel,
  MinimumOrderValueDto,
  OrderingTimeResponse,
  OrderRequest,
  OrderResponse,
  PreparationTimeResponse,
} from '@orderapp/api-clients/orderadmin-api-client';
import { CartProduct } from '../models/cart.model';
import { serviceTypes } from '../constants/service-type';

@Injectable({ providedIn: 'root' })
export class BusinessState {
  private readonly _business = new StateData<GetBusinessResponse>();
  private readonly _serviceTypes = new StateData<
    BusinessServiceTypeResponseModel[]
  >();
  private readonly _orderingTimes = new StateData<OrderingTimeResponse[]>([]);
  private readonly _preparationTimes = new StateData<PreparationTimeResponse[]>(
    [],
  );
  private readonly _menuProducts =
    new StateData<MenusWithProductsResponseModel>();
  private readonly _orderResponse = new StateData<OrderResponse>();
  private readonly _isBusinessClosed = new StateData<boolean>(closed);
  private readonly _deliveryZones = new StateData<DeliveryZone[]>([]);
  private readonly _minimumOrderValue = new StateData<MinimumOrderValueDto>();
  private readonly _businessConfiguration =
    new StateData<BusinessConfiguration>();
  public readonly serviceTypes$ = this._serviceTypes.asObservable();
  public readonly business$ = this._business.asObservable();
  public readonly menuProducts$ = this._menuProducts.asObservable();
  public readonly orderingTimes$ = this._orderingTimes.asObservable();
  public readonly preparationTimes$ = this._preparationTimes.asObservable();
  public readonly orderResponse$ = this._orderResponse.asObservable();
  public readonly isBusinessClosed$ = this._isBusinessClosed.asObservable();
  public readonly deliveryZones$ = this._deliveryZones.asObservable();
  public readonly minimumOrderValue$ = this._minimumOrderValue.asObservable();
  public readonly businessConfiguration$ =
    this._businessConfiguration.asObservable();

  public readonly _store = new BehaviorSubject<BusinessStore>(
    {} as BusinessStore,
  );
  public readonly _cart = new BehaviorSubject<CartProduct[]>([]);
  public readonly _selectedMenuId = new BehaviorSubject<number>(0);

  public readonly store$ = this._store
    .asObservable()
    .pipe(distinctUntilChanged());

  public readonly cart$ = this._cart
    .asObservable()
    .pipe(distinctUntilChanged());

  public readonly selectedMenuId$ = this._selectedMenuId
    .asObservable()
    .pipe(distinctUntilChanged());

  constructor(
    private readonly _businessService: BusinessService,
    private readonly _location: Location,
    private readonly _businessConfigurationService: BusinessConfigurationService,
  ) {
    this.store$.subscribe({
      next: ({
        businessType,
        city,
        businessName,
        businessId,
        qrCodeId,
        serviceTypeId,
      }) => {
        if (businessType && city && businessName && businessId) {
          this.getBusiness({
            businessType,
            city,
            businessName,
            businessId,
            qrCodeId,
            serviceTypeId,
          });
        }
      },
    });
  }

  get store(): BusinessStore {
    return this._store.getValue();
  }

  public setBusinessStore(params: BusinessStore) {
    const { businessType, city, businessName } = params;
    const currentStore = this.store;

    const equal =
      businessType === currentStore.businessType &&
      city === currentStore.city &&
      businessName === currentStore.businessName &&
      params.businessId === currentStore.businessId &&
      params.qrCodeId === currentStore.qrCodeId &&
      params.serviceTypeId === currentStore.serviceTypeId;

    if (businessType && city && businessName && !equal) {
      this._store.next(params);
    }
  }

  public saveSelectedState() {
    const selectedState = {
      businessStore: this._store.getValue(),
      orderResponse: this._orderResponse.data.getValue(),
    };

    sessionStorage.setItem('appState', JSON.stringify(selectedState));
  }

  public restoreState() {
    const storedState = sessionStorage.getItem('appState');
    if (storedState) {
      const { businessStore, orderResponse } = JSON.parse(storedState);

      if (businessStore) {
        this._store.next(businessStore);
      }
      if (orderResponse) {
        this._orderResponse.data.next(orderResponse);
      }
    }
  }

  public get cart(): CartProduct[] {
    return this._cart.getValue();
  }

  public setCart(cartProducts: CartProduct[]) {
    sessionStorage.setItem('cart-products', JSON.stringify(cartProducts));
    this._cart.next(cartProducts);
  }

  public get selectedMenuId(): number {
    return this._selectedMenuId.getValue();
  }

  public setselectedMenuId(selectedMenuId: number) {
    this._selectedMenuId.next(selectedMenuId);
  }

  public addCartProduct(cartProduct: CartProduct) {
    const currentCartProducts = this.cart;
    this._cart.next([...currentCartProducts, cartProduct]);
  }

  public resetCart() {
    sessionStorage.setItem('cart-products', '[]');
    this._cart.next([]);
  }

  public setIsBusinessClosed(isClosed: boolean) {
    this._business.isClosed.next(isClosed);
  }

  public getBusiness(params: BusinessStore): void {
    this._business.isLoading.next(true);
    this._business.error.next('');
    this._serviceTypes.isLoading.next(true);

    this._businessService
      .getBusiness(Number(params.businessId))
      .pipe(
        take(1),
        finalize(() => {
          this._business.isLoading.next(false);
          this._serviceTypes.isLoading.next(false);
        }),
        catchError((error) => {
          if (error.status === 404) {
            this._business.error.next('Display 404 component');
            return of(null);
          }

          throw error;
        }),
      )
      .subscribe({
        next: (business) => {
          if (business) {
            this._business.data.next(business);
            this._serviceTypes.data.next(business.serviceTypes ?? []);
          }
        },
      });
  }

  private get _takeAwayServiceTypeId(): number | undefined {
    const serviceTypes = this._serviceTypes.data.getValue();
    return serviceTypes.find(
      (serviceType) => serviceType.name === ActivatedModule.TakeAway,
    )?.serviceTypeId;
  }

  public fetchOrderingTimes(businessId: number, serviceType: string): void {
    this._orderingTimes.isLoading.next(true);

    this._businessService
      .fetchOrderingTimes(businessId, serviceType)
      .pipe(
        take(1),
        finalize(() => {
          this._orderingTimes.isLoading.next(false);
        }),
      )
      .subscribe({
        next: (orderingTimes) => {
          this._orderingTimes.data.next(orderingTimes);
        },
      });
  }

  public fetchMenuProducts(
    businessId: number,
    serviceTypeName: string,
    qrCodeId: number | null,
  ): void {
    this._menuProducts.isLoading.next(true);
    this._businessService
      .fetchMenuProducts(businessId, qrCodeId, serviceTypeName)
      .pipe(
        take(1),
        finalize(() => {
          this._menuProducts.isLoading.next(false);
        }),
      )
      .subscribe({
        next: (data) => {
          this._menuProducts.data.next(data);
        },
      });
  }

  public putOrder(order: OrderRequest): Promise<void> {
    this._orderResponse.isLoading.next(true);

    return new Promise<void>((resolve, reject) => {
      this._businessService
        .putOrder(order)
        .pipe(
          take(1),
          finalize(() => {
            this._orderResponse.isLoading.next(false);
          }),
        )
        .subscribe({
          next: (data) => {
            this._orderResponse.data.next(data);
            resolve();
          },
          error: (error) => {
            reject(error);
          },
        });
    });
  }

  public finalizeOrder(orderNumber: string): void {
    this._businessService.finalizeOrder(orderNumber).subscribe();
  }

  public fetchPreparationTimes(businessId: number, serviceType: string): void {
    this._preparationTimes.isLoading.next(true);

    this._businessService
      .fetchPreparationTimes(businessId, serviceType)
      .pipe(
        take(1),
        finalize(() => {
          this._preparationTimes.isLoading.next(false);
        }),
      )
      .subscribe({
        next: (preparationTimes) => {
          this._preparationTimes.data.next(preparationTimes);
        },
      });
  }

  public fetchDeliveryZones(businessId: number): void {
    this._deliveryZones.isLoading.next(true);

    this._businessService
      .fetchDeliveryZones(businessId)
      .pipe(
        take(1),
        finalize(() => {
          this._deliveryZones.isLoading.next(false);
        }),
      )
      .subscribe({
        next: (deliveryZones) => {
          this._deliveryZones.data.next(deliveryZones);
        },
      });
  }

  public getMinimumOrderValue(
    businessId: number,
    serviceTypeName: string,
  ): void {
    this._minimumOrderValue.isLoading.next(true);

    this._businessService
      .getMinimumOrderValue(businessId, serviceTypeName)
      .pipe(
        take(1),
        finalize(() => {
          this._minimumOrderValue.isLoading.next(false);
        }),
      )
      .subscribe({
        next: (minimumOrderValue) => {
          this._minimumOrderValue.data.next(minimumOrderValue);
        },
      });
  }

  public getBusinessConfiguration(businessId: number): void {
    this._businessConfiguration.isLoading.next(true);

    this._businessConfigurationService
      .getBusinessConfiguration(String(businessId))
      .pipe(
        take(1),
        finalize(() => {
          this._businessConfiguration.isLoading.next(false);
        }),
      )
      .subscribe({
        next: (config) => {
          this._businessConfiguration.data.next(config);
        },
      });
  }
}
