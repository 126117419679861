import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  BusinessesClient,
  DeliveryZone,
  DeliveryZonesClient,
  GetBusinessResponse,
  MenuProductsResponseModel,
  MenusClient,
  MenusWithProductsResponseModel,
  MinimumOrderValueDto,
  MinimumOrderValuesClient,
  OrderingTimeResponse,
  OrderingTimesClient,
  OrderRequest,
  OrderResponse,
  OrdersClient,
  PreparationTimeResponse,
  PreparationTimesClient,
  ProductsClient,
} from '@orderapp/api-clients/orderadmin-api-client';

@Injectable({ providedIn: 'root' })
export class BusinessService {
  constructor(
    private readonly _http: HttpClient,
    private readonly _businessesClient: BusinessesClient,
    private readonly _orderingTimesClient: OrderingTimesClient,
    private readonly _preparationTimesClient: PreparationTimesClient,
    private readonly _deliveryZonesClient: DeliveryZonesClient,
    private readonly _minimumOrderValuesClient: MinimumOrderValuesClient,
    private readonly _productsClient: ProductsClient,
    private readonly _ordersClient: OrdersClient,
    private readonly _menusClient: MenusClient,
  ) {}

  public getBusiness(businessId: number): Observable<GetBusinessResponse> {
    return this._businessesClient.getBusiness2(businessId);
  }

  public fetchOrderingTimes(
    businessId: number,
    serviceType: string,
  ): Observable<OrderingTimeResponse[]> {
    return this._orderingTimesClient.get(businessId, serviceType);
  }

  public fetchPreparationTimes(
    businessId: number,
    serviceType: string,
  ): Observable<PreparationTimeResponse[]> {
    return this._preparationTimesClient.get(businessId, serviceType);
  }

  public fetchDeliveryZones(businessId: number): Observable<DeliveryZone[]> {
    return this._deliveryZonesClient.get(businessId);
  }

  public getMinimumOrderValue(
    businessId: number,
    serviceTypeName: string,
  ): Observable<MinimumOrderValueDto> {
    return this._minimumOrderValuesClient.getMinimumOrderValue(
      businessId,
      serviceTypeName,
    );
  }

  public fetchMenuProducts(
    businessId: number,
    qrCodeId: number | null,
    serviceTypeName: string,
  ): Observable<MenusWithProductsResponseModel> {
    return this._productsClient.getBusinessMenus(
      businessId,
      qrCodeId,
      serviceTypeName,
    );
  }

  public putOrder(order: OrderRequest): Observable<OrderResponse> {
    return this._ordersClient.createOrder(order);
  }

  public finalizeOrder(orderNumber: string): Observable<void> {
    return this._ordersClient.finalizeOrder(orderNumber);
  }

  public getMenu(
    businessId: number,
    serviceTypeName: string,
    qrCodeId: number | null,
  ): Observable<number> {
    return this._menusClient.getByServiceType(
      businessId,
      serviceTypeName,
      qrCodeId,
    );
  }
}
