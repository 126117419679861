import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { CartProduct } from '../../core/models/cart.model';
import { ActivatedRoute } from '@angular/router';
import { BusinessFacade } from '../../core/facades/business.facade';
import { BusinessStore } from '../../core/models/business-store.model';
import { FormatBusinessName } from '../../shared/utility/format-title';
import { ActivatedModule } from '../../core/enums/activated-module.enum';
import { getServiceModuleByUrl } from '../../shared/utility/get-activated-service-module';
import { serviceTypes } from '../../core/constants/service-type';
import { environment } from 'apps/orderapp/src/environments/environment';
import { DomSanitizer, SafeHtml, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { OrdersService } from '../../core/services/orders.service';
import {
  ExtraDto,
  ExtraOptionDto,
  OrderRequest,
} from '@orderapp/api-clients/orderadmin-api-client';

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.scss'],
})
export class ShoppingCartComponent implements OnInit {
  menuType: 'Food' | 'Beverage' = 'Food';
  productCount = 1;
  cartProducts: CartProduct[] = [];
  businessStore: BusinessStore = {} as BusinessStore;
  businessId = 0;
  isInhouse = false;
  availableTime: string | null = sessionStorage.getItem('pickUpTime');
  serviceTypeModule: ActivatedModule = ActivatedModule.Inhouse;
  minimumOrderValue = 0;
  activatedModule = ActivatedModule;
  deliveryCost = 0;
  payFromQrCode = false;
  openTableId = '';
  skipGuestInformation = false;
  isPayTeaser = false;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    public readonly businessFacade: BusinessFacade,
    private translate: TranslateService,
    private sanitizer: DomSanitizer,
    private orderService: OrdersService,
    private readonly titleService: Title,
  ) {}

  ngOnInit(): void {
    this.isInhouse = this.location
      .path()
      .includes(serviceTypes[ActivatedModule.Inhouse].name.toLowerCase());
    this.payFromQrCode =
      Boolean(this.route.snapshot.queryParamMap.get('payFromQrCode')) ?? false;
    this.isPayTeaser =
      Boolean(this.route.snapshot.queryParamMap.get('payTeaser')) ?? false;
    this.openTableId =
      this.route.snapshot.queryParamMap.get('openTableId') ?? '';
    this.handlePayTeaser();
    this.serviceTypeModule = getServiceModuleByUrl(this.location.path());
    if (this.serviceTypeModule === ActivatedModule.Delivery) {
      this.deliveryCost = Number(sessionStorage.getItem('deliveryZoneCost'));
      this.minimumOrderValue = Number(
        sessionStorage.getItem('minimumOrderValue'),
      );
    }
    this.route.params.subscribe((params) => {
      this.handleRouteParams(params as BusinessStore);
    });

    if (this.businessStore.businessId) {
      let titleObservable;
      if (this.isPayTeaser) {
        titleObservable = this.translate.get(
          'inHouseOpenTable.paymentSummaryTitle',
        );
      } else {
        titleObservable = this.translate.get('shoppingcart.title');
      }

      titleObservable.subscribe((text) => {
        this.titleService.setTitle(
          FormatBusinessName(this.businessStore.businessName, text),
        );
      });

      this.businessId = Number(this.businessStore.businessId);
      this.getBusinessConfiguration();

      this.businessStore = {
        businessId: this.businessStore.businessId,
        businessName: this.businessStore.businessName,
        businessType: this.businessStore.businessType,
        city: this.businessStore.city,
        qrCodeId: this.businessStore.qrCodeId,
        // menuId: this.businessStore.menuId,
        serviceTypeId: this.businessStore.serviceTypeId,
      };
    }
    this.businessFacade.cart.subscribe((data) => {
      if (!this.payFromQrCode && !this.isPayTeaser) {
        this.cartProducts = data;
        if (this.cartProducts.length === 0) {
          switch (this.serviceTypeModule) {
            case ActivatedModule.Inhouse || ActivatedModule.HotelService:
              this.businessFacade.goToUrl(
                this.location.path().replace('/shopping-cart', ''),
              );
              break;
            case ActivatedModule.TakeAway:
              this.businessFacade.goToUrl(
                this.location.path().replace('/take-away/shopping-cart', ''),
              );
              break;
            case ActivatedModule.Delivery:
              this.businessFacade.goToUrl(
                this.location.path().replace('/delivery/shopping-cart', ''),
              );
              break;
            default:
              break;
          }
        }
      }
    });
  }

  private handleRouteParams(params: BusinessStore): void {
    const businessStore = params as BusinessStore;
    this.businessFacade.setBusinessStore(businessStore);
    let serviceTypeName = serviceTypes.Inhouse.name;
    this.getBusinessConfiguration();

    switch (this.serviceTypeModule) {
      case ActivatedModule.Inhouse:
        sessionStorage.setItem(
          'home-url',
          `/${environment.prePath}${businessStore.businessType}/${businessStore.city}/${businessStore.businessName}/${businessStore.businessId}/inhouse/${businessStore.qrCodeId}`,
        );
        serviceTypeName = serviceTypes.Inhouse.name;
        break;
      case ActivatedModule.Delivery:
        serviceTypeName = serviceTypes.Delivery.name;
        break;
      case ActivatedModule.HotelService:
        serviceTypeName = serviceTypes['Hotel Service'].name;
        break;
      case ActivatedModule.TakeAway:
        serviceTypeName = serviceTypes['Take away'].name;
        break;
      default:
        break;
    }
    if (!this.payFromQrCode && !this.isPayTeaser) {
      const cartProducts: CartProduct[] = JSON.parse(
        sessionStorage.getItem('cart-products') ?? '[]',
      );
      this.businessFacade.setCart(cartProducts);
    } else {
      this.orderService
        .getOrderByOpenTableId(this.openTableId ?? '')
        .subscribe((result) => {
          const orderItems = result.combinedOrderItems;
          if (this.isPayTeaser) {
            const orders = result.orders.map((x) => x.orderId);
            sessionStorage.setItem('order-numbers', orders.join(','));
          }
          const cartProducts: CartProduct[] = [];
          orderItems.forEach((element) => {
            const product = new CartProduct(element.product);
            product.variant =
              element?.product?.variants?.find(
                (x) => x.variantId === element.variantId,
              ) ?? null;
            product.quantity = element.productQuantity;
            product.extras = element.extras.map((extra) => {
              const mappedExtra = new ExtraDto();
              mappedExtra.extraId = extra.extraId;
              mappedExtra.name = extra.name;
              mappedExtra.selectionType = extra.selectionType;
              mappedExtra.options = extra.options?.map((option) => {
                const mappedOption = new ExtraOptionDto();
                mappedOption.id = option.id;
                mappedOption.name = option.name;
                mappedOption.price = option.price;
                mappedOption.quantity = option.quantity ?? 0; // Ensure quantity is not undefined
                return mappedOption;
              });
              return mappedExtra;
            });
            cartProducts.push(product);
          });
          sessionStorage.setItem('cart-products', JSON.stringify(cartProducts));
          sessionStorage.setItem(
            'order-numbers',
            result.orders.map((x) => x.orderNumber).join(','),
          );
          this.businessFacade.setCart(cartProducts);
          this.cartProducts = cartProducts;
        });
    }
    this.businessStore = params as BusinessStore;
  }

  onCounterChange(increase: boolean, productIndex: number): void {
    let productQuantity = this.cartProducts[productIndex].quantity;

    if (increase) {
      productQuantity++;
    } else {
      productQuantity -= productQuantity !== 1 ? 1 : 0;
    }

    this.cartProducts[productIndex].quantity = productQuantity;

    this.businessFacade.setCart(this.cartProducts);
  }

  removeProduct(index: number): void {
    this.cartProducts.splice(index, 1);
    this.businessFacade.setCart(this.cartProducts);
  }

  onContinue() {
    switch (this.serviceTypeModule) {
      case ActivatedModule.Inhouse:
        this.businessFacade.goToInhouseTipsUrl(
          this.payFromQrCode,
          this.openTableId,
        );
        break;
      case ActivatedModule.TakeAway:
        console.log('continue to next page');
        if (this.businessStore.qrCodeId != null) {
          console.log('going to tips page');
          this.businessFacade.goToServiceUrl('tips', this.serviceTypeModule);
        } else {
          console.log('going to checkout page');
          this.businessFacade.goToTakeAwayUrl('checkout');
        }
        break;
      case ActivatedModule.Delivery:
        this.businessFacade.goToDeliveryUrl('checkout');
        break;
      case ActivatedModule.HotelService:
        if (this.skipGuestInformation) {
          const order: OrderRequest = this.orderService.createOrder(
            this.businessId,
            this.serviceTypeModule,
            this.cartProducts,
            this.availableTime,
            this.deliveryCost,
            {
              fullName: '',
              phoneNumber: '',
              otherNotes: '',
              deliveryAddress: null,
              deliveryZoneId: null,
              qrCodeId:
                this.serviceTypeModule === ActivatedModule.HotelService
                  ? this.businessStore.qrCodeId
                  : null,
            },
          );
          this.orderService.processOrder(
            order,
            this.businessId,
            this.serviceTypeModule,
            this.isInhouse,
          );
        } else {
          this.businessFacade.goToHotelServiceUrl('checkout');
        }
        break;
      default:
        break;
    }
  }

  goHome() {
    this.businessFacade.goToHome(false, this.serviceTypeModule);
  }

  goBack(): void {
    this.businessFacade.goToUrl(
      this.location.path().replace('/shopping-cart', '/beverage-menu'),
    );
  }

  getProductPrice(index: number): number {
    const cartProduct = this.cartProducts[index];
    if (!cartProduct) {
      return 0;
    }

    let totalPrice = 0;
    let productTotalPrice = 0;

    if (cartProduct.variant) {
      productTotalPrice += cartProduct.quantity * cartProduct.variant.price;
    } else {
      productTotalPrice += cartProduct.quantity * cartProduct.price;
    }

    cartProduct.extras?.forEach((extra: ExtraDto) => {
      productTotalPrice +=
        extra.options?.reduce((priceSummed, extraOption) => {
          const optionPrice = extraOption.price ?? 0;
          const optionQuantity = extraOption.quantity ?? 0;
          return (
            priceSummed + optionPrice * optionQuantity * cartProduct.quantity
          );
        }, 0) ?? 0;
    });

    totalPrice += productTotalPrice;
    return totalPrice;
  }

  get getTotalPrice(): number {
    return this.getTotalPriceFn() + this.deliveryCost;
  }

  getTotalPriceFn(): number {
    return this.orderService.getTotalPriceFn(this.cartProducts);
  }

  getTranslatedMessage(): SafeHtml {
    const translatedText = this.translate.instant(
      'shoppingcart.minimumOrderValueText',
      {
        currency: 'CHF',
        minimumOrderValue: this.minimumOrderValue,
      },
    );
    return this.sanitizer.bypassSecurityTrustHtml(translatedText);
  }

  isMinimumOrderValueReached(): boolean {
    if (this.serviceTypeModule === ActivatedModule.Delivery) {
      const totalPrice = this.getTotalPriceFn();
      return totalPrice >= this.minimumOrderValue;
    } else {
      return true;
    }
  }
  getBusinessConfiguration() {
    if (
      this.businessStore.businessId &&
      this.serviceTypeModule === ActivatedModule.HotelService
    ) {
      this.businessFacade.getBusinessConfiguration(
        Number(this.businessStore.businessId),
      );
      this.businessFacade.businessConfigurations$.data$.subscribe((config) => {
        this.skipGuestInformation = config.hotelServiceConfiguration
          ?.skipGuestInformationStep
          ? config.hotelServiceConfiguration.skipGuestInformationStep
          : false;
      });
    }
  }

  handlePayTeaser() {
    if (this.isPayTeaser) {
      sessionStorage.setItem('payTeaser', JSON.stringify(true));
      sessionStorage.setItem('openTableId', this.openTableId);
    }
    this.isPayTeaser = JSON.parse(
      sessionStorage.getItem('payTeaser') ?? 'false',
    );

    if (this.isPayTeaser) {
      this.openTableId = sessionStorage.getItem('openTableId') ?? '';
      let currentPath = this.location.path();
      const index = currentPath.indexOf('/shopping-cart');
      if (index !== -1) {
        currentPath = currentPath.slice(0, index + '/shopping-cart'.length);
      }
      this.location.replaceState(currentPath);
    }
  }
}
