import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { PaymentService } from '../../core/services/payment.service';

@Component({
  selector: 'orderapp-frontend-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent {
  constructor(
    private _location: Location,
    private readonly _http: HttpClient,
    private payment: PaymentService,
  ) {}

  goBack(): void {
    this._location.back();
  }
}
