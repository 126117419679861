<ng-container
  *ngIf="{
    isNotLoading: businessFacade.business$.isNotLoading$ | async,
    hasNoError: businessFacade.business$.hasNoError$ | async,
    notTakingOrders: businessFacade.business$.isClosed$ | async,
    businessIsOpen: businessFacade.businessIsOpen$ | async,
    address: (businessFacade.business$.data$ | async)?.address,
  } as state"
>
  <div class="home__welcome">
    <ng-container>
      <p class="subtitle_menu_selections">
        {{ 'MenuSelection.LeadText' | translate }}
      </p>
    </ng-container>

    <div class="home__modules">
      <ng-container *ngIf="state.isNotLoading; else loader">
        <ng-container>
          <active-menu
            *ngFor="let menu of menusWithProducts.menus"
            [menuName]="menu?.menuName!"
            (click)="setMenuIdValue(menu)"
          ></active-menu>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <div>
    <p class="subtitle_menu_selections_botom">
      {{ 'MenuSelection.SwitchInfo' | translate }}
    </p>
  </div>

  <div class="home__address" *ngIf="state.isNotLoading && state.hasNoError">
    <orderapp-frontend-home-address
      [address]="state.address!"
    ></orderapp-frontend-home-address>
  </div>
</ng-container>
<ng-template #loader>
  <p class="home__placeholder">{{ 'global.loaderText' | translate }}</p>
  <loader-animation></loader-animation>
</ng-template>
