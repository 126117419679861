import { ActivatedModule } from '../enums/activated-module.enum';
import { serviceTypes } from '../constants/service-type';
import { BusinessServiceTypeResponseModel } from '@orderapp/api-clients/orderadmin-api-client';

const DEFAULT_ICON_CLASSES = 'fa-duotone fa-duotone-primary';

export class ServiceTypeUtil {
  static icon(serviceType: BusinessServiceTypeResponseModel): string {
    switch (serviceType.name?.toLowerCase()) {
      case serviceTypes[ActivatedModule.TakeAway].name.toLowerCase():
        return `${DEFAULT_ICON_CLASSES} fa-person-carry-box`;
      case serviceTypes[ActivatedModule.Inhouse].name.toLowerCase():
        return `${DEFAULT_ICON_CLASSES} fa-map-marker-check`;
      case serviceTypes[ActivatedModule.Delivery].name.toLowerCase():
        return `${DEFAULT_ICON_CLASSES} fa-moped`;
      case serviceTypes[ActivatedModule.HotelService].name.toLowerCase():
        return `${DEFAULT_ICON_CLASSES} fa-hotel`;
      default:
        return '';
    }
  }

  static title(serviceType: BusinessServiceTypeResponseModel): string {
    switch (serviceType.name?.toLowerCase()) {
      case serviceTypes[ActivatedModule.TakeAway].name.toLowerCase():
        return 'Zum Mitnehmen';
      case serviceTypes[ActivatedModule.Inhouse].name.toLowerCase():
        return 'Zum Menu';
      case serviceTypes[ActivatedModule.Delivery].name.toLowerCase():
        return 'Lieferung';
      case serviceTypes[ActivatedModule.HotelService].name.toLowerCase():
        return 'Zimmerservice';
      default:
        return '';
    }
  }

  static url(serviceType: BusinessServiceTypeResponseModel): string {
    switch (serviceType.name?.toLowerCase()) {
      case serviceTypes[ActivatedModule.TakeAway].name.toLowerCase():
        return serviceTypes[ActivatedModule.TakeAway].url;
      case serviceTypes[ActivatedModule.Inhouse].name.toLowerCase():
        return serviceTypes[ActivatedModule.Inhouse].url;
      case serviceTypes[ActivatedModule.Delivery].name.toLowerCase():
        return serviceTypes[ActivatedModule.Delivery].url;
      case serviceTypes[ActivatedModule.HotelService].name.toLowerCase():
        return serviceTypes[ActivatedModule.HotelService].url;
      default:
        return '';
    }
  }

  static description(serviceType: BusinessServiceTypeResponseModel): string {
    switch (serviceType.name?.toLowerCase()) {
      case serviceTypes[ActivatedModule.TakeAway].name.toLowerCase():
        return 'Bestellung aufgeben und bei uns vor Ort abholen.';
      case serviceTypes[ActivatedModule.Inhouse].name.toLowerCase():
        return 'Online bestellen und bezahlen. Schneller Service für dich.';
      case serviceTypes[ActivatedModule.Delivery].name.toLowerCase():
        return "Order now and we'll deliver your order to your address.";
      case serviceTypes[ActivatedModule.HotelService].name.toLowerCase():
        return 'Online bestellen und bezahlen. Schneller Service für dich.';
      default:
        return '';
    }
  }
}
