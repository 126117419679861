import {
  ProductDto,
  VariantDto2,
} from '@orderapp/api-clients/orderadmin-api-client';

export class CartProduct extends ProductDto {
  public menuId: number;
  public quantity: number;
  public comment: string;
  public variant: VariantDto2 | null;

  constructor(data?: ProductDto) {
    super(data);
    this.menuId = 0;
    this.quantity = 0;
    this.comment = '';
    this.variant = null;
  }
}
