import { Component, OnDestroy, OnInit } from '@angular/core';
import { BusinessFacade } from '../../core/facades/business.facade';
import { combineLatestWith, Subject, takeUntil } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { BusinessStore } from '../../core/models/business-store.model';
import * as moment from 'moment';
import 'moment/locale/de';
import { FormatBusinessName } from '../../shared/utility/format-title';
import { serviceTypes } from '../../core/constants/service-type';
import { ActivatedModule } from '../../core/enums/activated-module.enum';
import { TranslateService } from '@ngx-translate/core';
import {
  DeliveryZone,
  Duration,
  OrderingTimeResponse,
  PreparationTimeResponse,
} from '@orderapp/api-clients/orderadmin-api-client';
import { SessionStorageKeys } from '../../core/constants/session-storage-keys';

@Component({
  selector: 'orderapp-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./delivery.component.scss'],
})
export class DeliveryComponent implements OnInit, OnDestroy {
  private readonly _destroy$ = new Subject();
  moment = moment;
  datesToSelect: string[] = [];
  timesToSelect: string[] = [];
  deliveryZonesToSelect: DeliveryZone[] = [];

  dateSelected = '';
  timeSelected = '';
  selectedDeliveryZoneId = 0;
  deliveryZoneNotSelected = false;

  orderingTimes: OrderingTimeResponse[] = [];
  preparationTimes: PreparationTimeResponse[] = [];

  menuId = 0;

  constructor(
    public readonly businessFacade: BusinessFacade,
    private _router: Router,
    private readonly _route: ActivatedRoute,
    private readonly translationService: TranslateService,
  ) {
    this._route.params.pipe(takeUntil(this._destroy$)).subscribe({
      next: (params) => {
        const businessStore = params as BusinessStore;
        this.businessFacade.setBusinessStore(businessStore);
        this.businessFacade.fetchOrderingTimes(
          Number(businessStore.businessId),
          serviceTypes.Delivery.name,
        );
        this.businessFacade.fetchPreparationTimes(
          Number(businessStore.businessId),
          serviceTypes.Delivery.name,
        );
        this.businessFacade.fetchMenuProducts(
          Number(businessStore.businessId),
          serviceTypes.Delivery.name,
          null,
        );
        let title = '';
        translationService
          .get('home.moduleTitleDelivery')
          .subscribe((result) => (title = result));
        document.title = FormatBusinessName(businessStore.businessName, title);
        this.businessFacade.fetchDeliveryZones(
          Number(businessStore.businessId),
        );
        this.businessFacade.getMinimumOrderValue(
          Number(businessStore.businessId),
          serviceTypes.Delivery.name,
        );
      },
    });
  }

  ngOnInit(): void {
    this.businessFacade.orderingTimes$.data$
      .pipe(combineLatestWith(this.businessFacade.preparationTimes$.data$))
      .subscribe(([orderingTimes, preparationTimes]) => {
        if (orderingTimes.length) {
          this.orderingTimes = orderingTimes.filter((x) => x.isOpen);
          this.preparationTimes = preparationTimes;
          this.calculateDatesToShow();
        }
      });

    this.businessFacade.serviceTypes$.data$.subscribe((serviceTypes) => {
      if (
        serviceTypes &&
        serviceTypes.findIndex(
          (x) => x.name === ActivatedModule.Delivery && x.isEnabled,
        ) < 0
      ) {
        this.businessFacade.setBusinessIsClosed(true);
        this._router.navigate([
          decodeURIComponent(this._router.url).replace('/delivery', ''),
        ]);
      }
    });

    this.businessFacade.menuProducts$.data$.subscribe((menuProducts) => {
      if (menuProducts?.menus?.length == 0) {
        this.businessFacade.setBusinessIsClosed(true);
        this._router.navigate([
          decodeURIComponent(this._router.url).replace('/delivery', ''),
        ]);
      }
    });

    this.businessFacade.deliveryZones$.data$.subscribe((deliveryZones) => {
      this.deliveryZonesToSelect = deliveryZones;
    });

    this.businessFacade.minimumOrderValue$.data$.subscribe(
      (minimumOrderValue) => {
        sessionStorage.setItem(
          'minimumOrderValue',
          `${minimumOrderValue?.minimumPrice}`,
        );
      },
    );
  }

  public getDateLabel(date: string): string {
    const momentDate = moment(date);
    if (moment().isSame(momentDate, 'day')) return 'Heute';

    return momentDate.format('dd - DD.MM.YYYY');
  }

  onDateChange(event: string): void {
    this.dateSelected = event;
    this.timesToSelect = [];
    this.calculateTimesToShow();
  }

  goToClosedBusinessScreen(): void {
    this.businessFacade.setBusinessIsClosed(true);
    this._router.navigate([
      decodeURIComponent(this._router.url).replace('/delivery', ''),
    ]);
  }

  calculateDatesToShow(): void {
    const date = moment();
    this.datesToSelect = [];
    if (!this.orderingTimes.length) {
      this.goToClosedBusinessScreen();
      return;
    }

    while (this.datesToSelect.length < 5) {
      const dayNumber = date.isoWeekday();
      const indexOfOrderingDate = this.orderingTimes.findIndex(
        (x) => x.dayId === dayNumber,
      );
      if (indexOfOrderingDate >= 0) {
        this.datesToSelect.push(date.format('YYYYMMDD'));
      }

      date.add(1, 'days');
    }
    this.dateSelected = this.datesToSelect[0];
    this.calculateTimesToShow();
    if (this.timesToSelect.length == 0) {
      if (this.datesToSelect[1]) {
        this.dateSelected = this.datesToSelect[1];
        this.datesToSelect.shift();
        this.calculateTimesToShow();
      } else {
        this.goToClosedBusinessScreen();
      }
    }
  }
  calculateTimesToShow(): void {
    const today = moment();
    const dateSelected = moment(this.dateSelected);
    const orderingTimesForDay = this.orderingTimes.find(
      (x) => x.dayId === dateSelected.isoWeekday(),
    );
    const durations: Duration[] = orderingTimesForDay?.durations ?? [];
    this.timesToSelect = [];
    const timeInterval = this.preparationTimes[0]?.minutes;

    if (dateSelected.isSame(today, 'day')) {
      durations.forEach((duration) => {
        const opensTime = moment(duration.opens, 'HH:mm');
        const closesTime = moment(duration.closes, 'HH:mm');

        this.preparationTimes.forEach((preparationTime) => {
          preparationTime.availableTimeSlots.forEach((slot) => {
            const slotTime = moment(slot);
            if (slotTime.isBetween(opensTime, closesTime, undefined, '[]')) {
              this.timesToSelect.push(slotTime.format('HH:mm'));
            }
          });
        });
      });
    } else {
      if (timeInterval) {
        durations.forEach((duration) => {
          const opensTime = moment(duration.opens, 'HH:mm');
          const closesTime = moment(duration.closes, 'HH:mm');

          while (opensTime.isBefore(closesTime)) {
            this.timesToSelect.push(opensTime.format('HH:mm'));
            opensTime.add(timeInterval, 'minutes');
          }
        });
      }
    }

    this.timesToSelect = [...new Set(this.timesToSelect)].sort();
    if (this.timesToSelect.length) {
      this.timeSelected = this.timesToSelect[0];
    }
  }

  onTimeChange(event: string): void {
    this.timeSelected = event;
  }

  goOrder(): void {
    if (!this.selectedDeliveryZoneId) {
      this.deliveryZoneNotSelected = true;
      return;
    }

    if (!(this.dateSelected && this.timeSelected)) {
      return;
    }

    sessionStorage.setItem(
      'pickUpTime',
      `${moment(this.dateSelected).format('YYYY-MM-DD')}T${this.timeSelected}`,
    );

    const selectedDeliveryZone = this.deliveryZonesToSelect.find(
      (x) => x.deliveryZoneId === Number(this.selectedDeliveryZoneId),
    );
    sessionStorage.setItem(
      'deliveryZoneId',
      `${selectedDeliveryZone?.deliveryZoneId}`,
    );

    sessionStorage.setItem('deliveryZoneCost', `${selectedDeliveryZone?.cost}`);

    this.businessFacade.menuProducts$.data$.subscribe((menuProducts) => {
      if (menuProducts?.menus?.length == 1) {
        this.businessFacade.setSelectedMenuId(
          menuProducts?.menus[0].menuId ?? 0,
        );
        sessionStorage.setItem(
          SessionStorageKeys.MenuId,
          (menuProducts?.menus[0].menuId ?? 0).toString(),
        );
        if (menuProducts?.menus[0].foodProducts.length > 0) {
          this.businessFacade.goToDeliveryUrl('food-menu');
        } else {
          this.businessFacade.goToDeliveryUrl('beverage-menu');
        }
      }
      if (menuProducts?.menus && menuProducts.menus.length > 1) {
        this.businessFacade.goToDeliveryUrl('menu-selection');
      }
      if (menuProducts?.menus?.length == 0) {
        this.businessFacade.setBusinessIsClosed(true);
        this._router.navigate([
          decodeURIComponent(this._router.url).replace('/delivery', ''),
        ]);
      }
    });
  }

  public ngOnDestroy(): void {
    this._destroy$.next(null);
    this._destroy$.complete();
  }
}
