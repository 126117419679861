<div>
  <div class="shopping__header--icon" (click)="goBack()">
    <i class="far fa-chevron-left"></i>
  </div>
  <div class="title-container">
    <p *ngIf="menusOptions?.length == 1" class="title">
      {{ menuProducts.menuName }}
    </p>
    <mat-select
      *ngIf="menusOptions && menusOptions.length > 1"
      class="custom-select"
      [(ngModel)]="selectedMenuId"
      (selectionChange)="onSelectedMenuChange()"
    >
      <mat-option
        *ngFor="let menu of menusOptions"
        [value]="menu.menuId"
        class="custom-option"
      >
        {{ menu.menuName }}
      </mat-option>
    </mat-select>
  </div>

  <orderapp-frontend-menu-categories
    [categories]="menuProducts.beverageProducts"
  ></orderapp-frontend-menu-categories>

  <div *ngFor="let category of menuProducts.beverageProducts; let i = index">
    <orderapp-frontend-menu-items
      [foodTypeId]="2"
      [category]="category"
      [properties]="menuProducts.properties"
      [showBottomButton]="i === 0"
      [menuId]="selectedMenuId"
    ></orderapp-frontend-menu-items>
  </div>
</div>
