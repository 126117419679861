import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuItemsDetailsComponent } from './menu-items-details/menu-items-details.component';
import { DeviceService } from '../../../../core/services/device.service';
import { Router } from '@angular/router';
import { CartProduct } from '../../../../core/models/cart.model';
import { BusinessFacade } from '../../../../core/facades/business.facade';
import { getServiceModuleByUrl } from '../../../utility/get-activated-service-module';
import { ActivatedModule } from 'apps/orderapp/src/app/core/enums/activated-module.enum';
import {
  ExtraDto,
  ProductCategory,
  ProductProperty,
} from '@orderapp/api-clients/orderadmin-api-client';

@Component({
  selector: 'orderapp-frontend-menu-items',
  templateUrl: './menu-items.component.html',
  styleUrls: ['./menu-items.component.scss'],
})
export class MenuItemsComponent {
  @Input() foodTypeId = 1;
  @Input() hasBeverageProducts = true;
  @Input() category: ProductCategory = new ProductCategory();
  @Input() properties: ProductProperty[] = [];
  @Input() showBottomButton!: boolean;
  @Input() menuId: number = 0;

  deviceType: 'mobile' | 'tablet' | 'desktop' = 'mobile';
  cartProducts: CartProduct[] = [];

  constructor(
    public dialog: MatDialog,
    private deviceService: DeviceService,
    private _router: Router,
    public readonly businessFacade: BusinessFacade,
  ) {
    this.deviceType = this.deviceService.getDeviceType();
    this.businessFacade.cart.subscribe((data) => {
      this.cartProducts = data;
    });
  }

  openDetailsDialog(productIndex: number): void {
    this.deviceType = this.deviceService.getDeviceType();
    const dialogRef = this.dialog.open(MenuItemsDetailsComponent, {
      width: '100vw',
      height: '100%',
      hasBackdrop: this.deviceType === 'desktop',
      maxWidth: this.deviceType === 'desktop' ? '768px' : '992px',
      maxHeight: this.deviceType === 'desktop' ? '80vh' : '100vh',
      panelClass:
        this.deviceType !== 'desktop'
          ? ['animate__animated', 'animate__faster', 'animate__slideInUp']
          : [],
      data: {
        product: this.category.products?.[productIndex],
        properties: this.properties,
        menuId: this.menuId,
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.businessFacade.cart.subscribe((data) => {
        this.cartProducts = data;
      });
    });
  }

  hasProperties(productIndex: number): boolean {
    return Boolean(this.category.products?.[productIndex].properties);
  }

  getProperties(productIndex: number): ProductProperty[] {
    const productProperties = this.category.products?.[productIndex].properties;
    if (!productProperties) {
      return [];
    }

    return this.properties.filter((x) =>
      productProperties.includes(x.productPropertyId),
    );
  }

  getProductQuantityOnCart(productId: number): number {
    const products = this.cartProducts.filter(
      (x: any) => x.productId === productId,
    );
    return products.reduce(
      (quantitySum, product) => quantitySum + product.quantity,
      0,
    );
  }

  onContinue(): void {
    const serviceTypeModule = getServiceModuleByUrl(location.pathname);
    const beverageMenu = location.pathname.includes('beverage-menu');
    const goToUrl =
      this.hasBeverageProducts && !beverageMenu
        ? 'beverage-menu'
        : 'shopping-cart';

    switch (serviceTypeModule) {
      case ActivatedModule.Inhouse: {
        const targetUrl =
          serviceTypeModule === ActivatedModule.Inhouse &&
          this.hasBeverageProducts &&
          !beverageMenu
            ? 'beverage-menu'
            : 'shopping-cart';
        this.businessFacade.goToInhouseUrl(targetUrl);
        break;
      }
      case ActivatedModule.TakeAway:
        this.businessFacade.store.subscribe((businessStore) => {
          if (businessStore.qrCodeId != null) {
            console.log('going to page with qrcode', goToUrl);
            this.businessFacade.goToServiceUrl(goToUrl, serviceTypeModule);
          } else {
            console.log('going to page');
            this.businessFacade.goToTakeAwayUrl(goToUrl);
          }
        });
        break;
      case ActivatedModule.Delivery:
        goToUrl;
        this.businessFacade.goToDeliveryUrl(goToUrl);
        break;
      case ActivatedModule.HotelService:
        this.businessFacade.goToHotelServiceUrl(goToUrl);
        break;
      default:
        break;
    }
  }

  isContinueButtonDisabled(): boolean {
    if (this.foodTypeId === 1) {
      return this.cartProducts.length === 0 && !this.hasBeverageProducts;
    } else if (this.foodTypeId === 2) {
      return this.cartProducts.length === 0;
    }

    return false;
  }

  get getTotalPrice(): number {
    let totalPrice = 0;

    this.cartProducts.forEach((cartProduct: CartProduct) => {
      let productTotalPrice = 0;

      if (cartProduct.variant) {
        productTotalPrice += cartProduct.quantity * cartProduct.variant.price;
      } else {
        productTotalPrice += cartProduct.quantity * cartProduct.price;
      }

      cartProduct.extras.forEach((extra: ExtraDto) => {
        if (extra.options) {
          productTotalPrice +=
            extra.options.reduce(
              (priceSummed, extraOption) =>
                priceSummed + extraOption.price * extraOption.quantity,
              0,
            ) * cartProduct.quantity;
        }
      });

      totalPrice += productTotalPrice;
    });

    return totalPrice;
  }
}
