import { Component, Inject } from '@angular/core';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';

@Component({
  selector: 'payment-dialog',
  templateUrl: 'payment-dialog.component.html',
  styleUrls: ['payment-dialog.component.scss'],
})
export class PaymentDialogComponent {
  isPayTeaser = false;
  constructor(
    private bottomSheetRef: MatBottomSheetRef<PaymentDialogComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
  ) {}

  closeDialog(paymentMethod: number): void {
    this.bottomSheetRef.dismiss({ paymentMethod });
  }
}
