import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';
import { BusinessFacade } from '../../../core/facades/business.facade';
import { TypeService } from '../../../core/services/types.service';
import { TranslateService } from '@ngx-translate/core';
import { BusinessServiceTypeResponseModel } from '@orderapp/api-clients/orderadmin-api-client';
import { ServiceTypeUtil } from '../../../core/utils/service-type.util';
import { ActivatedRoute } from '@angular/router';
import { ActivatedModule } from '../../../core/enums/activated-module.enum';

@Component({
  selector: 'activated-module',
  templateUrl: './activated-module.component.html',
  styleUrls: ['./activated-module.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivatedModuleComponent implements OnInit {
  @HostBinding('style.--primary-color') primaryColor = '';
  @HostBinding('style.--primary-color-background') primaryColorBackground = '';

  @Input() serviceType!: BusinessServiceTypeResponseModel;
  pickup = false;

  constructor(
    private _businessFacade: BusinessFacade,
    private readonly _route: ActivatedRoute,
    public _typeService: TypeService,
    private cdr: ChangeDetectorRef,
    private _translate: TranslateService,
  ) {}

  hexToRgb(hex: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result) {
      const r = parseInt(result[1], 16);
      const g = parseInt(result[2], 16);
      const b = parseInt(result[3], 16);
      const a = parseFloat('0.08');
      return `rgba(${r},${g},${b},${a})`;
    }
    return 'rgba(255, 102, 0, 0.08);';
  }

  get title(): string {
    if (this.pickup && this.serviceType.name === ActivatedModule.TakeAway) {
      return this._typeService.title(ActivatedModule.Pickup);
    }
    return this._typeService.title(this.serviceType.name);
  }
  get description(): string {
    if (this.pickup && this.serviceType.name === ActivatedModule.TakeAway) {
      return this._typeService.description(ActivatedModule.Pickup);
    }
    return this._typeService.description(this.serviceType.name);
  }

  ngOnInit() {
    this.pickup = this._route.snapshot.data['pickup'];
    this._businessFacade.business$.data$.subscribe((res) => {
      this.primaryColor = res.primaryColor || '#ff6600';
      this.primaryColorBackground = this.hexToRgb(res.primaryColor);
    });

    this._translate.onLangChange.subscribe(() => {
      this.cdr.detectChanges();
    });
    console.log('pickup', this.pickup);
  }

  protected readonly ServiceTypeUtil = ServiceTypeUtil;
}
