import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';

const SESSION_TIMEOUT_DURATION = 15 * 60 * 1000; // 15 minutes in milliseconds
const WARNING_SESSION_TIMEOUT_DURATION = 3 * 60 * 1000; // 3 minutes in milliseconds
const INACTIVITY_SESSION_TIMEOUT_DURATION = 1 * 60 * 1000; // 1 minutes in milliseconds

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private sessionTimer!: null | ReturnType<typeof setTimeout>;
  private warningTimer!: null | ReturnType<typeof setTimeout>;
  private inactivityTimer!: null | ReturnType<typeof setTimeout>;
  intervalSubscription: Subscription | undefined;
  private countdownTimer = 180; // 3 minutes

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(private _router: Router) {}

  startSessionTimer(onSessionTimeout: () => void) {
    this.stopSessionTimer();
    this.sessionTimer = setTimeout(onSessionTimeout, SESSION_TIMEOUT_DURATION);
  }

  startWarningTimer(onWarning: () => void) {
    this.stopWarningTimer();
    this.warningTimer = setTimeout(
      onWarning,
      SESSION_TIMEOUT_DURATION -
        INACTIVITY_SESSION_TIMEOUT_DURATION -
        WARNING_SESSION_TIMEOUT_DURATION,
    );
  }

  startInactivityTimer(onSessionInactivity: () => void) {
    this.stopInactivityTimer();
    this.inactivityTimer = setTimeout(
      onSessionInactivity,
      INACTIVITY_SESSION_TIMEOUT_DURATION,
    );
  }

  startCountDownInterval() {
    this.intervalSubscription = interval(1000).subscribe(() => {
      if (this.countdownTimer > 0) {
        this.countdownTimer--;
        const minutes = Math.floor(this.countdownTimer / 60);
        const seconds = this.countdownTimer % 60;
        const formattedTime = `${minutes}:${seconds
          .toString()
          .padStart(2, '0')}`;
        document.title = `Jamatu: Scan & Order - ${formattedTime}`;
      } else {
        this._router.navigate([sessionStorage.getItem('home-url')]).then(() => {
          location.reload();
        });
      }
    });
  }

  clearInterval() {
    if (this.intervalSubscription) {
      this.countdownTimer = 180; // 3 minutes
      this.intervalSubscription.unsubscribe();
    }
  }

  resetInactivityTimer() {
    this.stopInactivityTimer();
    this.inactivityTimer = null;
  }

  resetSessionTimer() {
    this.stopSessionTimer();
    this.sessionTimer = null;
  }

  resetWaringTimer() {
    this.stopWarningTimer();
    this.sessionTimer = null;
  }

  stopSessionTimer() {
    if (this.sessionTimer) {
      clearTimeout(this.sessionTimer);
    }
    this.sessionTimer = null;
  }

  stopWarningTimer() {
    if (this.warningTimer) {
      clearTimeout(this.warningTimer);
    }
    this.warningTimer = null;
  }

  stopInactivityTimer() {
    if (this.inactivityTimer) {
      clearTimeout(this.inactivityTimer);
    }
  }
}
