<header class="header" *ngIf="headerLogo">
  <div class="header__back-icon" *ngIf="showBackIcon">
    <i class="far fa-chevron-left"></i>
  </div>
  <img class="header__logo" [src]="headerLogo" alt="order app logo" />
  <button *ngIf="!showBackIcon" mat-button class="header__language" [matMenuTriggerFor]="menu">
    {{ language }}
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="onChangeLanguage('de')">Deutsch</button>
    <button mat-menu-item (click)="onChangeLanguage('en')">English</button>
    <button mat-menu-item (click)="onChangeLanguage('it')">Italian</button>
  </mat-menu>
</header>
