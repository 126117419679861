<ng-container
  *ngIf="{
    isNotLoading: businessFacade.business$.isNotLoading$ | async,
    hasNoError: businessFacade.business$.hasNoError$ | async,
    notTakingOrders: businessFacade.business$.isClosed$ | async,
    businessIsOpen: businessFacade.businessIsOpen$ | async,
    address: (businessFacade.business$.data$ | async)?.address
  } as state"
>
  <div class="home__welcome">
    <ng-container *ngIf="state.isNotLoading && state.hasNoError">
      <orderapp-frontend-home-welcome></orderapp-frontend-home-welcome>
    </ng-container>

    <div class="home__modules">
      <ng-container *ngIf="state.isNotLoading; else loader">
        <ng-container *ngIf="state.hasNoError; else error">
          <ng-container
            *ngIf="state.businessIsOpen && !state.notTakingOrders && serviceTypes[0].isEnabled; else closed"
          >
            <activated-module
              *ngFor="let serviceType of serviceTypes"
              [serviceType]="serviceType"
              [routerLink]="getServiceLink(serviceType.name)"
            ></activated-module>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <div class="home__address" *ngIf="state.isNotLoading && state.hasNoError">
    <orderapp-frontend-home-address
      [address]="state.address!"
    ></orderapp-frontend-home-address>
  </div>
</ng-container>

<ng-template #loader>
  <loader-animation></loader-animation>
</ng-template>

<ng-template #closed>
  <p class="home__placeholder">
    {{ (businessFacade.business$.data$ | async)?.name }} -
    {{ 'global.closedText' | translate }}
  </p>
</ng-template>

<ng-template #error>
  <p class="home__placeholder">
    {{ businessFacade.business$.error$ | async }}
  </p>
</ng-template>
