<div class="counter-container">
  <div
    *ngIf="!isDisabled"
    class="decrease-button"
    [ngClass]="reachedLimitDecrease || counter === minValue ? 'disabled' : ''"
    (click)="decrease()"
  >
    <i class="fas fa-minus"></i>
  </div>
  <p class="counter-number">{{ counter }}</p>
  <div
    *ngIf="!isDisabled"
    class="increase-button"
    [ngClass]="reachedLimitIncrease || counter === maxValue ? 'disabled' : ''"
    (click)="increase()"
  >
    <i class="fas fa-plus"></i>
  </div>
</div>
