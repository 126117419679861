import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'apps/orderapp/src/environments/environment';
import {
  BusinessConfiguration,
  BusinessConfigurationClient,
} from '@orderapp/api-clients/orderadmin-api-client';

@Injectable({ providedIn: 'root' })
export class BusinessConfigurationService {
  private readonly apiUrl = `${environment.API_URL}/BusinessConfiguration`;
  constructor(
    private readonly _http: HttpClient,
    private readonly _businessConfigurationClient: BusinessConfigurationClient,
  ) {}

  getBusinessConfiguration(
    businessId: string,
  ): Observable<BusinessConfiguration> {
    return this._businessConfigurationClient.getConfigurationByBusinessId(
      Number(businessId),
    );
  }
}
