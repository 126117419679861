import { Component, HostBinding, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DeviceService } from '../../../../../core/services/device.service';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { BusinessFacade } from '../../../../../core/facades/business.facade';

import { CartProduct } from '../../../../../core/models/cart.model';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { SnackbarService } from 'apps/orderapp/src/app/core/services/snack-bar.service';
import { TranslateService } from '@ngx-translate/core';
import { FoodType } from 'apps/orderapp/src/app/core/enums/foodType';
import {
  Extra,
  Product,
  ProductProperty,
  VariantDto2,
} from '@orderapp/api-clients/orderadmin-api-client';

class ProductDetailsData {
  product: Product;
  properties: ProductProperty[];
  menuId: number;

  constructor() {
    this.product = new Product();
    this.properties = [];
    this.menuId = 0;
  }
}

@Component({
  selector: 'orderapp-frontend-menu-items-details',
  templateUrl: './menu-items-details.component.html',
  styleUrls: ['./menu-items-details.component.scss'],
  animations: [
    trigger('dialogAnimation', [
      state('open', style({ opacity: 1, transform: 'scale(1)' })),
      state('closed', style({ transform: 'translate3d(0, 100%, 0)' })),
      transition('open => closed', [animate('500ms ease-out')]),
      transition('closed => open', [
        animate(
          '500ms ease-out',
          style({ transform: 'translate3d(0, 100%, 0)' }),
        ),
      ]),
    ]),
  ],
})
export class MenuItemsDetailsComponent implements OnInit {
  productCount = 1;
  modalState = 'open';

  deviceType: 'mobile' | 'tablet' | 'desktop' = 'mobile';
  form: FormGroup = new FormGroup({});
  totalPrice = 0;
  totalBasePrice = 0;
  extrasPrice = 0;
  cartProducts: any[] = [];
  isScreenOnTop = true;
  selectedVariantId = 0;
  highlightUnselectedExtras = false;

  showProductComment = false;

  private previousTouchPosition!: number;
  public direction!: string;
  isBevarage = false;

  @HostBinding('style.--primary-color') primaryColor =
    sessionStorage.getItem('primaryColor');
  @HostBinding('style.--primary-color-background') primaryColorBackground =
    sessionStorage.getItem('primaryColorBackground');
  @HostBinding('style.--primary-color-box-shadow') primaryColorBoxShadow =
    sessionStorage.getItem('primaryColorBoxShadow');

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ProductDetailsData,
    private deviceService: DeviceService,
    public dialogRef: MatDialogRef<MenuItemsDetailsComponent>,
    public readonly businessFacade: BusinessFacade,
    private readonly _snackbarService: SnackbarService,
    private readonly translationService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.deviceType = this.deviceService.getDeviceType();
    this.initializeProductForm();
  }

  onTouchStart(event: TouchEvent) {
    this.previousTouchPosition = event.touches[0].clientY;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  imageElement = document.querySelector('.image');

  onTouchMove(event: TouchEvent) {
    const currentTouchPosition = event.touches[0].clientY;
    if (this.previousTouchPosition !== null) {
      if (currentTouchPosition > this.previousTouchPosition) {
        this.direction = 'down';
        if (this.isScreenOnTop) {
          this.modalState = 'closed';
          setTimeout(() => {
            this.dialogRef.close();
          }, 200);
        }
      } else if (currentTouchPosition < this.previousTouchPosition) {
        this.direction = 'up';
      } else {
        this.direction = 'none';
      }
    }
    this.previousTouchPosition = currentTouchPosition;
  }

  onScroll(event: Event) {
    const scrollTop = (event.target as HTMLElement).scrollTop;
    const isOnTop = scrollTop === 0;
    this.isScreenOnTop = isOnTop;
  }

  initializeProductForm() {
    const product = this.data.product;
    if (product.foodTypeId === FoodType.Beverages) {
      this.isBevarage = true;
    }

    const firstVariant = this.getDefaultVariant(product);

    this.form = new FormGroup({
      extras: new FormArray([]),
      variantId: new FormControl(firstVariant ? firstVariant?.variantId : null),
      comment: new FormControl(''),
    });
    this.totalBasePrice = firstVariant ? firstVariant.price : product.price;
    this.totalPrice += this.totalBasePrice;
    this.selectedVariantId = firstVariant?.variantId ?? 0;
  }

  hasProperties(): boolean {
    return Boolean(this.data.product.properties);
  }

  getProperties(): ProductProperty[] {
    const productProperties = this.data.product.properties;
    if (!productProperties) {
      return [];
    }

    return this.data.properties.filter((x) =>
      productProperties.includes(x.productPropertyId),
    );
  }

  onVariantChange(event: any): void {
    const variant = this.data.product.variants.find(
      (x) => x.variantId === event.value,
    );
    this.form.get('variantId')?.setValue(event.value);
    this.totalBasePrice = variant?.price || this.totalBasePrice;
  }

  get extrasArray() {
    return this.form.get('extras') as FormArray;
  }

  onSingleExtraChange(event: any, extraIndex: number): void {
    if (event.value === null) {
      const extraToRemove = this.data.product.extras[extraIndex];
      this.extrasArray.controls.forEach((element: any, index: number) => {
        if (element.value.extraId == extraToRemove.extraId) {
          this.extrasArray.removeAt(index);
        }
      });

      return;
    }
    const extra = this.data.product.extras[extraIndex];
    const formExtras = this.extrasArray.value;
    const formExtraIndex = formExtras.findIndex(
      (x: any) => x.extraId === extra.extraId,
    );
    if (formExtraIndex >= 0) {
      this.extrasArray.removeAt(formExtraIndex);
    }

    this.addExtra(extra, event.value);
  }

  onMultipleExtraChange(event: MatCheckboxChange, extraIndex: number): void {
    const extra = this.data.product.extras[extraIndex];
    const formExtras = this.extrasArray?.value ?? [];
    const formExtraIndex = formExtras.findIndex(
      (x: any) => x.extraId === extra.extraId,
    );
    if (event.checked) {
      if (formExtraIndex >= 0) {
        this.addExtraOption(extra, formExtraIndex, Number(event.source.value));
      } else {
        this.addExtra(extra, Number(event.source.value));
      }
    } else {
      if (extra.options) {
        const formExtraOptionIndex = formExtras[
          formExtraIndex
        ].options.findIndex(
          (x: any) =>
            x.name === extra.options?.[Number(event.source.value)].name,
        );
        this.removeExtraOption(formExtraIndex, formExtraOptionIndex);
      }
    }
  }

  addExtraOption(
    extra: Extra,
    extraIndex: number,
    extraOptionIndex: number,
  ): void {
    if (extra.options) {
      const extraOption = extra.options[extraOptionIndex];
      (this.extrasArray.at(extraIndex).get('options') as FormArray).push(
        new FormGroup({
          id: new FormControl(extraOption?.id, []),
          name: new FormControl(extraOption?.name, []),
          price: new FormControl(extraOption?.price, []),
          quantity: new FormControl(extraOption.quantity, []),
          extraOptionId: new FormControl(extra.extraId, []),
        }),
      );
    }
  }

  removeExtraOption(extraIndex: number, extraOptionIndex: number): void {
    (this.extrasArray.at(extraIndex).get('options') as FormArray).removeAt(
      extraOptionIndex,
    );

    if (
      (this.extrasArray.at(extraIndex).get('options') as FormArray).length === 0
    ) {
      this.extrasArray.removeAt(extraIndex);
    }
  }

  addExtra(extra: Extra, extraOptionIndex: number): void {
    if (extra.options) {
      const extraOption = extra.options[extraOptionIndex];
      this.extrasArray.push(
        new FormGroup({
          extraId: new FormControl(extra.extraId, []),
          name: new FormControl(extra.name, []),
          selectionType: new FormControl(extra.selectionType, []),
          productId: new FormControl(extra.productId, []),
          options: new FormArray([
            new FormGroup({
              id: new FormControl(extraOption?.id, []),
              name: new FormControl(extraOption?.name, []),
              price: new FormControl(extraOption?.price, []),
              quantity: new FormControl(extraOption.quantity, []),
              extraOptionId: new FormControl(extra.extraId, []),
            }),
          ]),
        }),
      );
    }
  }

  onCounterChange(increase: boolean): void {
    if (increase) {
      this.productCount++;
    } else {
      this.productCount -= this.productCount !== 1 ? 1 : 0;
    }
  }

  get getTotalPrice(): number {
    let totalPrice = this.totalBasePrice * this.productCount;

    const formExtras = this.extrasArray?.value ?? [];

    formExtras.forEach((extra: Extra) => {
      if (extra.options) {
        totalPrice += extra.options.reduce((priceSummed, extraOption) => {
          return (
            priceSummed +
            extraOption.price * extraOption.quantity * this.productCount
          );
        }, 0);
      }
    });

    return totalPrice;
  }

  removeComment() {
    this.showProductComment = !this.showProductComment;
    this.form.get('comment')?.setValue('');
  }

  saveProductToCart(): void {
    const unselectedExtras = this.getUnselectedRequiredExtras();
    if (unselectedExtras.length > 0) {
      this.translationService
        .get('global.selectMinimumOptions', {
          extraTitle: unselectedExtras.map((x) => x.name).join(', '),
        })
        .subscribe((result) => {
          this._snackbarService.trigger(result, 'x', {
            panelClass: 'error-snackbar',
          });
          this.highlightUnselectedExtras = true;
        });

      return;
    }

    let variant = null;
    const vId = this.form.get('variantId')?.value;
    if (vId) {
      variant = this.data.product.variants.find((x) => x.variantId === vId);
    }
    const productToAddToCart: CartProduct = {
      productId: this.data.product.productId,
      categoryId: this.data.product.categoryId,
      foodTypeId: this.data.product.foodTypeId,
      brandId: this.data.product.brandId,
      imageUrl: this.data.product.imageUrl,
      description: this.data.product.description,
      name: this.data.product.name,
      price: this.data.product.price,
      properties: this.data.product.properties,
      quantity: this.productCount,
      extras: this.extrasArray?.value ?? [],
      variant: new VariantDto2({
        variantId: variant?.variantId,
        productId: variant?.productId,
        name: variant?.name,
        price: variant?.price || 0,
      }),
      comment: this.form.controls['comment'].value,
      menuId: this.data.menuId,
    } as CartProduct;

    this.businessFacade.cart.subscribe((data) => {
      /* empty block */
    });
    this.businessFacade.addCartProduct(productToAddToCart);
    const cartProducts: CartProduct[] = JSON.parse(
      sessionStorage.getItem('cart-products') ?? '[]',
    );
    cartProducts.push(productToAddToCart);
    sessionStorage.setItem('cart-products', JSON.stringify(cartProducts));
    this.modalState = 'closed';
    setTimeout(() => {
      this.dialogRef.close();
    }, 200);
  }

  optionHasRangeAndSelected(option: any) {
    if (this.extrasArray.length > 0) {
      return this.extrasArray.controls.some((x: any) => {
        const options = x.get('options')?.value;
        if (options?.length > 0) {
          const seletctedOption = options.filter((o: any) => o.id == option.id);
          return (
            seletctedOption.length > 0 && option.maximumAllowedQuantity > 1
          );
        }
        return false;
      });
    }
    return false;
  }

  getUnselectedRequiredExtras(): Extra[] {
    const extras = this.data.product.extras;
    if (extras.length > 0) {
      return extras.filter((extra: Extra) => {
        if (extra.isRequired) {
          return !this.extrasArray.controls.some((x: any) => {
            return x.value.extraId === extra.extraId;
          });
        }
        return false;
      });
    }
    return [];
  }

  checkIfExtraIsSelected(extraId: number): boolean {
    if (this.extrasArray.length > 0) {
      return this.extrasArray.controls.some((x: any) => {
        return x.value.extraId === extraId;
      });
    }
    return false;
  }

  onExtraCounterChange(increase: boolean, option: any, item: any) {
    const selectedExtras = this.extrasArray;
    const isSelectedChange = this.isQuantityChangedOnSelected(
      selectedExtras,
      item,
      option,
    );
    if (isSelectedChange) {
      selectedExtras.controls.forEach((x: any) => {
        const options = x.get('options')?.value;
        options.forEach((y: any) => {
          if (y.name === option.name && y.price === option.price) {
            y.quantity = increase ? y.quantity + 1 : y.quantity - 1;
          }
        });
      });
    } else {
      const extra = this.data.product.extras.filter(
        (x: any) => x.extraId === item.extraId,
      )[0];
      extra.options?.forEach((x: any) => {
        if (x.name === option.name && x.price === option.price) {
          x.quantity = increase ? x.quantity + 1 : x.quantity - 1;
        }
      });
    }
  }

  isQuantityChangedOnSelected(
    selectedExtras: any,
    item: any,
    option: any,
  ): boolean {
    let isChanged = false;
    selectedExtras.controls.forEach((x: any) => {
      const options = x.value.options;
      options.forEach((y: any) => {
        if (y.name === option.name && y.price === option.price) {
          isChanged = true;
        }
      });
    });
    return isChanged;
  }
  getDefaultVariant(product: Product): any {
    if (product.variants.length > 0) {
      const defaultVariant = product.variants.filter(
        (x) => x.isDefault === true,
      );
      if (defaultVariant.length > 0) {
        return defaultVariant[0];
      } else {
        return product.variants[0];
      }
    }
    return null;
  }
}
