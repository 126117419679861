import { ActivatedModule } from '../enums/activated-module.enum';

export const serviceTypes = {
  [ActivatedModule.TakeAway]: {
    id: 2,
    name: 'Take away',
    url: 'take-away',
  },
  [ActivatedModule.Delivery]: {
    id: 1,
    name: 'Delivery',
    url: 'delivery',
  },
  [ActivatedModule.Inhouse]: {
    id: 3,
    name: 'Inhouse',
    url: 'inhouse',
  },
  [ActivatedModule.HotelService]: {
    id: 4,
    name: 'Hotel Service',
    url: 'hotel-service',
  },
};
