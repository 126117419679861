import { Component, Input } from '@angular/core';
import { AddressModel } from '@orderapp/api-clients/orderadmin-api-client';

@Component({
  selector: 'orderapp-frontend-home-address',
  templateUrl: './home-address.component.html',
  styleUrls: ['./home-address.component.scss'],
})
export class HomeAddressComponent {
  @Input() address!: AddressModel;
  constructor() {}
}
