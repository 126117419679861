import { serviceTypes } from '../../core/constants/service-type';
import { ActivatedModule } from '../../core/enums/activated-module.enum';

export const getServiceModuleByUrl = (url: string): ActivatedModule => {
  const lowerPathUrl = url.toLowerCase();

  for (const module of Object.values(ActivatedModule)) {
    if (lowerPathUrl.includes(serviceTypes[module].url)) {
      return module;
    }
  }

  return ActivatedModule.TakeAway;
};
