import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  getDeviceType(): 'mobile' | 'tablet' | 'desktop' {
    const width = window.innerWidth;

    if (width < 768) {
      return 'mobile';
    } else if (width < 992) {
      return 'tablet';
    } else {
      return 'desktop';
    }
  }
}
