import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'active-menu',
  templateUrl: './active-menu.component.html',
  styleUrls: ['./active-menu.component.scss'],
})
export class ActiveMenuComponent implements OnInit {
  @Input() menuName: string = '';
  ngOnInit(): void {}
}
