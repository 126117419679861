import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarRef,
} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) {}

  public trigger(
    message: string,
    action?: string,
    config?: MatSnackBarConfig,
  ): MatSnackBarRef<any> {
    const panelClass = [config?.panelClass ?? 'success-snackbar'] as string[];

    const snackbarConfig: MatSnackBarConfig = {
      duration: 1000000,
      verticalPosition: 'top',
      ...config,
      panelClass,
    };

    return this.snackBar.open(message, action ?? 'x', snackbarConfig);
  }
}
