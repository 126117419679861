import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  constructor(private translate: TranslateService) {}

  initializeTranslateService() {
    this.translate.setDefaultLang('de');
    const storedLang = localStorage.getItem('lng');
    this.translate.use(storedLang || this.translate.currentLang);
  }

  getTranslation(key: string): string {
    return this.translate.instant(key);
  }
}
