import { Component, HostBinding, OnInit } from '@angular/core';
import { BusinessFacade } from '../../core/facades/business.facade';
import { filter, Subject, take, takeUntil, tap } from 'rxjs';
import {
  BusinessServiceTypeResponseModel,
  MenusWithProductsResponseModel,
  MenuWithProductsResponseModel,
} from '@orderapp/api-clients/orderadmin-api-client';
import { BusinessStore } from '../../core/models/business-store.model';
import { ActivatedModule } from '../../core/enums/activated-module.enum';
import { ActivatedRoute } from '@angular/router';
import { getServiceModuleByUrl } from '../../shared/utility/get-activated-service-module';
import { Location } from '@angular/common';

@Component({
  selector: 'orderapp-menu-selection',
  templateUrl: './menu-selection.component.html',
  styleUrls: ['./menu-selection.component.scss'],
})
export class MenuSelectionComponent implements OnInit {
  private readonly _destroy$ = new Subject();
  menusWithProducts: MenusWithProductsResponseModel =
    new MenusWithProductsResponseModel();
  businessStore: BusinessStore = {} as BusinessStore;
  currentActivatedModule!: ActivatedModule;
  serviceTypeModule: ActivatedModule = ActivatedModule.TakeAway;
  serviceType: BusinessServiceTypeResponseModel[] | undefined = [];

  @HostBinding('style.--primary-color') primaryColor =
    sessionStorage.getItem('primaryColor');
  @HostBinding('style.--primary-color-background') primaryColorBackground =
    sessionStorage.getItem('primaryColorBackground');
  @HostBinding('style.--primary-color-box-shadow') primaryColorBoxShadow =
    sessionStorage.getItem('primaryColorBoxShadow');
  constructor(
    public readonly businessFacade: BusinessFacade,
    private readonly _route: ActivatedRoute,
    private location: Location,
  ) {
    this.initSubscriptions();
  }
  ngOnInit(): void {
    this.businessFacade.fetchMenuProducts(
      Number(this.businessStore.businessId),
      this.serviceTypeModule,
      Number(this.businessStore.qrCodeId) || null,
    );
    const navigationEntries = performance.getEntriesByType('navigation');
    if (
      navigationEntries.length > 0 &&
      (navigationEntries[0] as PerformanceNavigationTiming).type === 'reload'
    ) {
      this.handleReload();
    }
  }

  setMenuIdValue(menu: MenuWithProductsResponseModel) {
    this.businessFacade.setSelectedMenuId(menu?.menuId!);
    if (menu?.foodProducts.length > 0) {
      const foodUrl = 'food-menu';
      switch (this.serviceTypeModule) {
        case ActivatedModule.Delivery:
          this.businessFacade.goToDeliveryUrl(foodUrl);
          break;
        case ActivatedModule.Inhouse:
          this.businessFacade.goToInhouseServiceUrl(foodUrl);
          break;
        case ActivatedModule.TakeAway:
          this.businessFacade.goToTakeAwayUrl(foodUrl);
          break;
        case ActivatedModule.HotelService:
          this.businessFacade.goToHotelServiceUrl(foodUrl);
          break;
        default:
          console.warn('Unknown service type module');
          break;
      }
    } else {
      const beverageUrl = '/beverage-menu';
      switch (this.serviceTypeModule) {
        case ActivatedModule.Delivery:
          this.businessFacade.goToDeliveryUrl(beverageUrl);
          break;
        case ActivatedModule.Inhouse:
          this.businessFacade.goToInhouseServiceUrl(beverageUrl);
          break;
        case ActivatedModule.TakeAway:
          this.businessFacade.goToTakeAwayUrl(beverageUrl);
          break;
        case ActivatedModule.HotelService:
          this.businessFacade.goToHotelServiceUrl(beverageUrl);
          break;
        default:
          console.warn('Unknown service type module');
          break;
      }
    }
  }
  redirectBackToHome() {
    this.businessFacade.goToUrl(sessionStorage.getItem('home-url') as string);
  }
  handleReload() {
    this.businessFacade.setBusinessStore(this.businessStore);

    this.businessFacade.menuProducts$.data$
      .pipe(takeUntil(this._destroy$))
      .subscribe((menuProducts) => {
        if (menuProducts) {
          this.menusWithProducts = menuProducts;
        }
      });
  }

  initSubscriptions() {
    this.serviceTypeModule = getServiceModuleByUrl(this.location.path());
    this._route.params
      .pipe(
        takeUntil(this._destroy$),
        tap((params) => {
          this.businessStore = { ...params } as BusinessStore;
          this.businessFacade.setBusinessStore(this.businessStore);
          this.businessFacade.serviceTypes$.data$
            .pipe(
              tap((item) => {
                if (
                  item == null ||
                  this.businessStore.serviceTypeId === undefined
                ) {
                  this.businessStore.serviceTypeId = this
                    .serviceTypeModule as unknown as number;
                }
              }),
              filter((item) => item !== null),
              take(1),
              tap((item) => {
                this.businessFacade.setBusinessStore(this.businessStore);
                this.businessFacade.menuProducts$.data$
                  .pipe(take(1))
                  .subscribe((menuProducts) => {
                    if (menuProducts) {
                      this.menusWithProducts = menuProducts;
                    }
                  });
                this.currentActivatedModule = Object.values(item).find(
                  (type) =>
                    type.serviceTypeId === this.businessStore.serviceTypeId,
                )?.name as ActivatedModule;
              }),
            )
            .subscribe();
        }),
      )
      .subscribe();

    this.businessFacade.serviceTypes$.data$.subscribe((serviceType) => {
      if (!serviceType) {
        this.businessFacade.getBusiness(this.businessStore);
      }
      this.serviceType = serviceType?.filter(
        (module) => module.name === this.serviceTypeModule,
      );
    });
  }
}
