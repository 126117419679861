import {
  animate,
  animateChild,
  group,
  query,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const routeTransitionAnimations = trigger('triggerName', [
  transition(
    'Home => TakeAway, Home => Food, TakeAway => Food, Food => Beverage, Food => ShoppingCart, Beverage => ShoppingCart, ShoppingCart => Checkout, ShoppingCart => Tips, Checkout => Confirmation',
    [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          right: 0,
          width: '100%',
        }),
      ]),
      query(':enter', [style({ left: '100%', opacity: 0 })]),
      query(':leave', animateChild()),
      group([
        query(':leave', [
          animate('400ms ease-out', style({ left: '-100%', opacity: 0 })),
        ]),
        query(':enter', [
          animate('400ms ease-out', style({ left: '0%', opacity: 1 })),
        ]),
      ]),
      query(':enter', animateChild()),
    ],
  ),
  transition(
    'Beverage => Food, Food => TakeAway, TakeAway => Home, ShoppingCart => Beverage, ShoppingCart => Food , Checkout => ShoppingCart, Tips => ShoppingCart, Confirmation => Home, Food => HotelService, HotelService => Home',
    [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
        }),
      ]),
      query(':enter', [style({ left: '-100%', opacity: 0 })]),
      query(':leave', animateChild()),
      group([
        query(':leave', [
          animate('400ms ease-out', style({ left: '100%', opacity: 0 })),
        ]),
        query(':enter', [
          animate('400ms ease-out', style({ left: '0%', opacity: 1 })),
        ]),
      ]),
      query(':enter', animateChild()),
    ],
  ),
  transition(
    'Home => HotelService, HotelService => Food, Food => Beverage, Food => ShoppingCart, Beverage => ShoppingCart, ShoppingCart => Checkout, Checkout => Confirmation',
    [
      style({ position: 'relative' }),
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          right: 0,
          width: '100%',
        }),
      ]),
      query(':enter', [style({ left: '100%', opacity: 0 })]),
      query(':leave', animateChild()),
      group([
        query(':leave', [
          animate('400ms ease-out', style({ left: '-100%', opacity: 0 })),
        ]),
        query(':enter', [
          animate('400ms ease-out', style({ left: '0%', opacity: 1 })),
        ]),
      ]),
      query(':enter', animateChild()),
    ],
  ),
]);
