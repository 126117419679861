import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  QrcodeResponse,
  QrcodesClient,
} from '@orderapp/api-clients/orderadmin-api-client';

@Injectable({ providedIn: 'root' })
export class QrcodeService {
  constructor(
    private _http: HttpClient,
    private _qrCodesClient: QrcodesClient,
  ) {}

  public getQrcodeDetails(qrCodeId: number): Observable<QrcodeResponse> {
    return this._qrCodesClient.get(qrCodeId);
  }
}
