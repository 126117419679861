import { Component, OnDestroy } from '@angular/core';
import { ActivatedModule } from '../../../core/enums/activated-module.enum';
import { BusinessStore } from '../../../core/models/business-store.model';
import { Subject, takeUntil } from 'rxjs';
import { BusinessFacade } from '../../../core/facades/business.facade';
import { HelperService } from '../../../core/services/helper.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { FormatBusinessName } from '../../../shared/utility/format-title';
import { BusinessServiceTypeResponseModel } from '@orderapp/api-clients/orderadmin-api-client';
import { QrcodeService } from '../../../core/services/qrcode.service';

@Component({
  selector: 'orderapp-frontend-hotel-service',
  templateUrl: './hotel-service.component.html',
  styleUrls: ['./hotel-service.component.scss'],
})
export class HomeHotelServiceComponent implements OnDestroy {
  public readonly modules = [
    ActivatedModule.TakeAway,
    ActivatedModule.Delivery,
  ];

  private readonly _destroy$ = new Subject();
  businessStore: BusinessStore = {} as BusinessStore;
  isInhouse = false;
  serviceTypes!: BusinessServiceTypeResponseModel[];

  constructor(
    public readonly businessFacade: BusinessFacade,
    private readonly _route: ActivatedRoute,
    public readonly helperService: HelperService,
    public readonly _qrCodeService: QrcodeService,
  ) {
    this._route.params.pipe(takeUntil(this._destroy$)).subscribe({
      next: (params) => {
        const businessStore = params as BusinessStore;
        this.businessFacade.setBusinessStore(businessStore);
        this.businessFacade.serviceTypes$.data$.subscribe((item) => {
          this.serviceTypes = item?.filter(
            (module) =>
              module.name.toLowerCase() ===
              ActivatedModule.HotelService.toLowerCase(),
          );
        });
        this.isInhouse = !!params['qrCodeId'];
        if (this.isInhouse) {
          this._qrCodeService
            .getQrcodeDetails(params['qrCodeId'])
            .subscribe((result) => {
              if (!result.isActive) {
                this.businessFacade.setBusinessIsClosed(true);
              }
            });
        }
        this.businessStore = businessStore;
        sessionStorage.setItem(
          'home-url',
          `/${environment.prePath}${businessStore.businessType}/${businessStore.city}/${businessStore.businessName}/${businessStore.businessId}`,
        );

        document.title = FormatBusinessName(
          businessStore.businessName,
          'Abholen',
        );
      },
    });
    this.businessFacade.resetCart();
  }

  getServiceLink(serviceType: string): string {
    return `/${environment.prePath}${this.businessStore.businessType}/${this.businessStore.city}/${this.businessStore.businessName}/${this.businessStore.businessId}/${this.helperService.toSeoUrl(serviceType)}/${this.businessStore.qrCodeId}/pick-up`;
  }

  public ngOnDestroy(): void {
    this._destroy$.next(null);
    this._destroy$.complete();
  }
}
