import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BusinessFacade } from '../../core/facades/business.facade';
import { OrdersService } from '../../core/services/orders.service';
import { FormatBusinessName } from '../../shared/utility/format-title';
import { ActivatedModule } from '../../core/enums/activated-module.enum';
import { getServiceModuleByUrl } from '../../shared/utility/get-activated-service-module';
import { BusinessStore } from '../../core/models/business-store.model';
import { QrcodeService } from '../../core/services/qrcode.service';
import {
  FileResponse,
  OrderResponse,
  QrcodeResponse,
} from '@orderapp/api-clients/orderadmin-api-client';
import { forkJoin } from 'rxjs';
import { BusinessService } from './../../core/services/business.service';
import { OpenTableService } from './../../core/services/opentable-service';
import { SessionStorageKeys } from '../../core/constants/session-storage-keys';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent implements OnInit {
  order: OrderResponse | null = new OrderResponse();
  pickupTime: string | null = sessionStorage.getItem('pickUpTime');
  isInhouse = false;
  isDownloadLoading = false;
  serviceTypeModule: ActivatedModule = ActivatedModule.TakeAway;
  activatedModuleEnum = ActivatedModule;
  qrCode: QrcodeResponse = {} as QrcodeResponse;
  businessStore: BusinessStore = {} as BusinessStore;
  payFromQrCode = false;
  openTableId = '';
  navigated = false;
  businessId = '';

  constructor(
    private router: Router,
    public readonly businessFacade: BusinessFacade,
    public readonly _orderService: OrdersService,
    public readonly _qrCodeService: QrcodeService,
    private route: ActivatedRoute,
    private businessService: BusinessService,
    private readonly openTableService: OpenTableService,
  ) {}

  ngOnInit(): void {
    this.payFromQrCode =
      Boolean(this.route.snapshot.queryParamMap.get('payFromQrCode')) ?? false;
    this.openTableId =
      this.route.snapshot.queryParamMap.get('openTableId') ?? '';

    this.isInhouse = this.router.url.includes(
      ActivatedModule.Inhouse.toLowerCase(),
    );
    this.serviceTypeModule = getServiceModuleByUrl(this.router.url);
    console.log('businessStore', this.businessStore);
    let navigated = false;
    this.businessFacade.store.subscribe((store) => {
      console.log('Store', store);
      this.businessId = store.businessId;
      console.log('businessId', this.businessId);
      this.markAsPaidAndClearsession();

      if (!store.businessId && !this.navigated) {
        let destinationUrl = '/';
        switch (this.serviceTypeModule) {
          case ActivatedModule.Inhouse:
            destinationUrl = sessionStorage.getItem('home-url') || '/';
            break;
          case ActivatedModule.TakeAway:
            destinationUrl = sessionStorage.getItem('home-url') || '/';
            break;
          case ActivatedModule.Delivery:
            destinationUrl = this.router.url.replace(
              '/delivery/confirmation',
              '',
            );
            break;
          case ActivatedModule.HotelService:
            destinationUrl = this.router.url.replace('/confirmation', '');
            break;
          default:
            break;
        }

        console.log('destinationUrl', destinationUrl);
        if (navigated === false) {
          this.businessFacade.goToUrl(destinationUrl);
        }
        this.businessStore = store;
        this.businessFacade.resetCart();
      }
      document.title = FormatBusinessName(
        store.businessName,
        'Bestellbestätigung',
      );
      this.businessStore = store;
      this.businessFacade.resetCart();
    });

    this.businessFacade.orderResponse$.data$.subscribe((data) => {
      if (data) {
        this.order = data;
      }
    });

    if (
      this.serviceTypeModule === ActivatedModule.Inhouse ||
      this.serviceTypeModule === ActivatedModule.HotelService ||
      this.serviceTypeModule === ActivatedModule.TakeAway
    ) {
      if (this.businessStore.qrCodeId != null) {
        this._qrCodeService
          .getQrcodeDetails(Number(this.businessStore.qrCodeId))
          .subscribe((result) => {
            if (result) {
              // this.qrCode = result;
            }
          });
      }
    }
  }

  onDownloadInvoice(orderId: number) {
    this.isDownloadLoading = true;
    if (!this.payFromQrCode) {
      this._orderService.downloadInvoice(orderId).subscribe(
        (response: FileResponse) => {
          const downloadLink = document.createElement('a');
          const url = window.URL.createObjectURL(response.data);
          downloadLink.href = url;
          downloadLink.download = `${this.order?.orderNumber}-invoice.pdf`;
          downloadLink.click();
          window.URL.revokeObjectURL(url);
          this.isDownloadLoading = false;
        },
        () => {
          this.isDownloadLoading = false;
        },
      );
    } else {
      this._orderService
        .downloadInvoiceByOpenTableId(this.openTableId)
        .subscribe(
          (response: FileResponse) => {
            const downloadLink = document.createElement('a');
            const url = window.URL.createObjectURL(response.data);
            downloadLink.href = url;
            downloadLink.download = `${this.openTableId}-invoice.pdf`;
            downloadLink.click();
            window.URL.revokeObjectURL(url);
            this.isDownloadLoading = false;
          },
          () => {
            this.isDownloadLoading = false;
          },
        );
    }
  }

  onNewOrder() {
    let shouldRedirectToHome = true;
    this.businessFacade.resetCart();
    this.businessFacade.goToHome(true, this.serviceTypeModule);
  }

  markAsPaidAndClearsession() {
    const payTeaser = Boolean(sessionStorage.getItem('payTeaser'));
    if (payTeaser) {
      this.openTableId = sessionStorage.getItem('openTableId') ?? '';
      const orders = sessionStorage.getItem('order-numbers');
      if (orders) {
        const orderNumbers = orders.split(',');
        const finalizedOrders = orderNumbers.map((orderNumber) =>
          this.businessService.finalizeOrder(orderNumber),
        );
        forkJoin(finalizedOrders).subscribe(() => {
          this.openTableService
            .markAsPaid(this.businessId, this.openTableId)
            .subscribe({
              next: () => {
                this.businessFacade.saveAppState();
                this.cleanSessionStorage();
              },
            });
        });
      }
    }
  }
  cleanSessionStorage() {
    sessionStorage.removeItem('order-numbers');
    sessionStorage.removeItem('payTeaser');
    sessionStorage.removeItem('openTableId');
    sessionStorage.removeItem(SessionStorageKeys.MenuId);
  }
}
