import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { NotFoundComponent } from './modules/not-found/not-found.component';
import { TakeAwayComponent } from './modules/take-away/take-away.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FoodMenuComponent } from './modules/food-menu/food-menu.component';
import { BeverageMenuComponent } from './modules/beverage-menu/beverage-menu.component';
import { MenuSelectionComponent } from './modules/menu-selection/menu-selection.component';

/**
 * Translation
 */
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { InputCounterModule } from './shared/components/button/input-counter/input-counter.module';
import { ShoppingCartComponent } from './modules/shopping-cart/shopping-cart.component';
import { CheckoutComponent } from './modules/checkout/checkout.component';
import { MaterialModule } from './shared/components/material.module';
import { ConfirmationComponent } from './modules/confirmation/confirmation.component';
import { SessionModalComponent } from './shared/components/session-modal/session-modal.component';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { SessionService } from 'apps/orderapp/src/app/core/services/session.service';
import { HomeInhouseComponent } from './modules/home/inhouse/inhouse.component';
import { HomeWelcomeComponent } from './modules/home/home-welcome/home-welcome.component';
import { HomeAddressComponent } from './modules/home/home-address/home-address.component';
import { HomeTakeAwayComponent } from './modules/home/take-away/take-away.component';
import { HomeHotelServiceComponent } from './modules/home/hotel-service/hotel-service.component';
import { HotelServiceComponent } from './modules/hotel-service/hotel-service.component';
import { RequestInterceptor } from './app.interceptor';
import { TipsComponent } from './modules/tips/tips.component';
import { TipPercentageComponent } from './modules/tips/tip-percentage/tip-percentage.component';
import { DeliveryComponent } from './modules/delivery/delivery.component';
import { API_BASE_URL } from '@orderapp/api-clients/orderadmin-api-client';
import { environment } from '../environments/environment';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HomeInhouseComponent,
    TakeAwayComponent,
    NotFoundComponent,
    MenuSelectionComponent,
    FoodMenuComponent,
    BeverageMenuComponent,
    ShoppingCartComponent,
    TipsComponent,
    TipPercentageComponent,
    CheckoutComponent,
    ConfirmationComponent,
    SessionModalComponent,
    HomeWelcomeComponent,
    HomeAddressComponent,
    HomeTakeAwayComponent,
    HomeHotelServiceComponent,
    HotelServiceComponent,
    DeliveryComponent,
  ],
  imports: [
    HttpClientModule,
    CommonModule,
    BrowserAnimationsModule,
    BrowserModule,
    RouterModule,
    SharedModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    InputCounterModule,
    MaterialModule,
    TranslateModule.forRoot({
      defaultLanguage: localStorage.getItem('lng') || 'de',
      // defaultLanguage: 'de',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    SessionService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    {
      provide: API_BASE_URL,
      useValue: environment.BASE_API_URL,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
