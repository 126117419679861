import { Component, OnDestroy, OnInit } from '@angular/core';
import { BusinessFacade } from '../../core/facades/business.facade';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MenuItemsDetailsComponent } from '../../shared/components/menu/menu-items/menu-items-details/menu-items-details.component';
import { MatDialog } from '@angular/material/dialog';
import { BusinessStore } from '../../core/models/business-store.model';
import { filter, Subject, switchMap, takeUntil } from 'rxjs';
import { environment } from '../../../environments/environment';
import { FormatBusinessName } from '../../shared/utility/format-title';
import { Title } from '@angular/platform-browser';
import { ActivatedModule } from '../../core/enums/activated-module.enum';
import { serviceTypes } from '../../core/constants/service-type';
import { getServiceModuleByUrl } from '../../shared/utility/get-activated-service-module';
import { CartProduct } from '../../core/models/cart.model';
import {
  BusinessServiceTypeResponseModel,
  MenusWithProductsResponseModel,
  MenuWithProductsResponseModel,
} from '@orderapp/api-clients/orderadmin-api-client';
import { RouteService } from '../../core/services/router.service';
import { SessionStorageKeys } from '../../core/constants/session-storage-keys';

@Component({
  selector: 'orderapp-frontend-food-menu',
  templateUrl: './food-menu.component.html',
  styleUrls: ['./food-menu.component.scss'],
})
export class FoodMenuComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  menuProducts: MenuWithProductsResponseModel =
    new MenuWithProductsResponseModel();
  businessStore: BusinessStore = {} as BusinessStore;
  selectedMenuId: number = 0;
  selectedMenu = new MenuWithProductsResponseModel();
  serviceTypeModule: ActivatedModule = ActivatedModule.TakeAway;
  serviceType: BusinessServiceTypeResponseModel[] | undefined = [];
  menusOptions: MenuWithProductsResponseModel[] | undefined = [];

  constructor(
    public readonly businessFacade: BusinessFacade,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private location: Location,
    private titleService: Title,
    private routeService: RouteService,
  ) {}

  ngOnInit(): void {
    this.serviceTypeModule = getServiceModuleByUrl(this.location.path());
    this.checkIfModuleIsEnable();
    this.route.params.forEach((res) => {
      this.titleService.setTitle(
        FormatBusinessName(res['businessName'], 'Speisekarte'),
      );
    });
    this.businessFacade.menuProducts$.data$
      .pipe(
        filter((menuProducts) => !menuProducts),
        switchMap(() => this.route.params.pipe(takeUntil(this.destroy$))),
      )
      .subscribe((params) => {
        this.handleRouteParams(params as BusinessStore);
      });

    this.businessFacade.store
      .pipe(
        filter((store) => !store.businessId),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.handleNoBusinessId();
      });

    this.businessFacade.menuProducts$.data$
      .pipe(
        filter(
          (menusWithProducts) =>
            menusWithProducts !== null && menusWithProducts !== undefined,
        ),
        takeUntil(this.destroy$),
      )
      .subscribe((menusWithProducts) => {
        this.handleMenuProducts(menusWithProducts);
      });
  }

  checkIfModuleIsEnable() {
    this.businessFacade.serviceTypes$.data$.subscribe((serviceType) => {
      this.serviceType = serviceType?.filter(
        (module) => module.name === this.serviceTypeModule,
      );
      if (this.serviceType?.length && !this.serviceType[0].isEnabled) {
        this.businessFacade.goToHome(true, this.serviceTypeModule);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private handleRouteParams(params: BusinessStore): void {
    const businessStore = params as BusinessStore;
    this.businessFacade.setBusinessStore(businessStore);
    let serviceTypeName = serviceTypes.Inhouse.name;
    switch (this.serviceTypeModule) {
      case ActivatedModule.Inhouse:
        sessionStorage.setItem(
          'home-url',
          `/${environment.prePath}${businessStore.businessType}/${
            businessStore.city
          }/${businessStore.businessName}/${businessStore.businessId}/${
            serviceTypes[ActivatedModule.Inhouse].url
          }/${businessStore.qrCodeId}`,
        );
        serviceTypeName = serviceTypes.Inhouse.name;
        break;
      case ActivatedModule.TakeAway:
        if (this.businessStore.qrCodeId != null) {
          sessionStorage.setItem(
            'home-url',
            `/${environment.prePath}${businessStore.businessType}/${businessStore.city}/${businessStore.businessName}/${businessStore.businessId}/take-away/${businessStore.qrCodeId}`,
          );
        } else {
          sessionStorage.setItem(
            'home-url',
            `/${environment.prePath}${businessStore.businessType}/${businessStore.city}/${businessStore.businessName}/${businessStore.businessId}`,
          );
        }
        serviceTypeName = serviceTypes['Take away'].name;
        break;
      case ActivatedModule.HotelService:
        sessionStorage.setItem(
          'home-url',
          `/${environment.prePath}${businessStore.businessType}/${
            businessStore.city
          }/${businessStore.businessName}/${businessStore.businessId}/${
            serviceTypes[ActivatedModule.HotelService].url
          }/${businessStore.qrCodeId}`,
        );
        serviceTypeName = serviceTypes['Hotel Service'].name;
        break;
      case ActivatedModule.Delivery:
        sessionStorage.setItem(
          'home-url',
          `/${environment.prePath}${businessStore.businessType}/${businessStore.city}/${businessStore.businessName}/${businessStore.businessId}`,
        );
        serviceTypeName = serviceTypes.Delivery.name;
        break;
      default:
        break;
    }
    this.businessFacade.fetchOrderingTimes(
      Number(businessStore.businessId),
      serviceTypeName,
    );
    this.businessFacade.fetchPreparationTimes(
      Number(businessStore.businessId),
      serviceTypeName,
    );
    this.businessFacade.fetchMenuProducts(
      Number(businessStore.businessId),
      serviceTypeName,
      Number(businessStore.qrCodeId) || null,
    );
    const cartProducts: CartProduct[] = JSON.parse(
      sessionStorage.getItem('cart-products') ?? '[]',
    );
    this.businessFacade.setCart(cartProducts);
    this.businessStore = params as BusinessStore;
  }

  private handleNoBusinessId(): void {
    switch (this.serviceTypeModule) {
      case ActivatedModule.Inhouse:
        this.businessFacade.goToUrl(
          sessionStorage.getItem('home-url') as string,
        );
        break;
      case ActivatedModule.TakeAway:
        console.log('qrCodeId', this.businessStore.qrCodeId);
        if (this.businessStore.qrCodeId != null) {
          this.businessFacade.goToUrl(
            this.router.url.replace(
              '/take-away/' + this.businessStore.qrCodeId + '/food-menu',
              '',
            ),
          );
        } else {
          this.businessFacade.goToUrl(
            this.router.url.replace('/take-away/food-menu', ''),
          );
        }
        break;
      case ActivatedModule.Delivery:
        this.businessFacade.goToUrl(
          this.router.url.replace('/delivery/food-menu', ''),
        );
        break;
      case ActivatedModule.HotelService:
        this.businessFacade.goToUrl(this.router.url.replace('/food-menu', ''));
        break;
      default:
        break;
    }
  }

  private handleMenuProducts(
    menusWithProducts: MenusWithProductsResponseModel,
  ): void {
    if (menusWithProducts?.menus && menusWithProducts?.menus?.length == 1) {
      if (
        menusWithProducts?.menus?.[0]?.beverageProducts?.length === 0 &&
        menusWithProducts?.menus?.[0]?.foodProducts?.length === 0
      ) {
        switch (this.serviceTypeModule) {
          case ActivatedModule.Inhouse:
            this.businessFacade.goToUrl(
              sessionStorage.getItem('home-url') as string,
            );
            break;
          case ActivatedModule.TakeAway:
            console.log('qrCodeId', this.businessStore.qrCodeId);
            if (this.businessStore.qrCodeId != null) {
              this.businessFacade.goToUrl(
                this.router.url.replace(
                  '/take-away/' + this.businessStore.qrCodeId + '/food-menu',
                  '',
                ),
              );
            } else {
              this.businessFacade.goToUrl(
                this.router.url.replace('/take-away/food-menu', ''),
              );
            }
            break;
          case ActivatedModule.Delivery:
            this.businessFacade.goToUrl(
              this.router.url.replace('/delivery/food-menu', ''),
            );
            break;
          case ActivatedModule.HotelService:
            this.businessFacade.goToUrl(
              this.router.url.replace('/food-menu', ''),
            );
            break;
          default:
            break;
        }
      } else {
        this.businessFacade.selectedMenuid$.subscribe((selectedMenuId) => {
          if (selectedMenuId == 0) {
            const availableProducts = menusWithProducts.menus?.filter(
              (x) => x.foodProducts.length > 0,
            );
            if (availableProducts && availableProducts.length > 0) {
              this.selectedMenuId = availableProducts[0].menuId ?? 0;
            }
            this.setMenuAndOptions(selectedMenuId, menusWithProducts);
          } else {
            this.setMenuAndOptions(selectedMenuId, menusWithProducts);
          }
        });
      }
    } else {
      this.businessFacade.selectedMenuid$.subscribe((selectedMenuId) => {
        this.setMenuAndOptions(selectedMenuId, menusWithProducts);
      });
      if (this.routeService.getPreviousUrl()?.includes('beverage-menu')) {
        this.setDefaultMenuOrGoToSelection(menusWithProducts);
      }
    }
  }

  openBottomSheet(): void {
    const dialogRef = this.dialog.open(MenuItemsDetailsComponent, {
      width: '100vw',
      height: '100vh',
      maxWidth: '700px',
    });

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    dialogRef.afterClosed().subscribe((result) => {});
  }

  onSelectedMenuChange() {
    this.businessFacade.setSelectedMenuId(this.selectedMenuId);
    sessionStorage.setItem(
      SessionStorageKeys.MenuId,
      this.selectedMenuId.toString(),
    );
  }

  setMenuAndOptions(
    selectedMenuId: number,
    menusWithProducts: MenusWithProductsResponseModel,
  ): void {
    this.selectedMenuId =
      selectedMenuId !== 0
        ? selectedMenuId
        : Number(sessionStorage.getItem(SessionStorageKeys.MenuId) ?? 0);
    const selectedMenu = menusWithProducts?.menus?.filter(
      (x) => x.menuId === this.selectedMenuId,
    )[0];
    if (selectedMenu) {
      this.menuProducts = selectedMenu;
    }
    this.menusOptions = menusWithProducts?.menus?.filter(
      (x) => x.foodProducts.length > 0,
    );
  }

  setDefaultMenuOrGoToSelection(
    menusWithProducts: MenusWithProductsResponseModel,
  ): void {
    if ((menusWithProducts?.menus?.length ?? 0) > 0) {
      let availableMenus: MenuWithProductsResponseModel[] = [];
      if (this.selectedMenuId > 0) {
        availableMenus =
          menusWithProducts?.menus?.filter(
            (x) =>
              x.menuId === this.selectedMenuId && x.foodProducts.length > 0,
          ) ?? [];
      } else {
        availableMenus =
          menusWithProducts?.menus?.filter((x) => x.foodProducts.length > 0) ??
          [];
      }
      if (availableMenus.length > 0) {
        this.selectedMenuId = availableMenus[0].menuId ?? 0;
      } else {
        this.businessFacade.goToUrl(
          this.router.url.replace('food-menu', 'menu-selection'),
        );
      }
    }
  }
}
