<div
  class="dialog-modal"
  (scroll)="onScroll($event)"
  [@dialogAnimation]="modalState"
  (touchstart)="onTouchStart($event)"
  (touchmove)="onTouchMove($event)"
>
  <div
    class="image"
    [ngStyle]="{
      'background-image': 'url(' + data.product.imageUrl + '/h=260)',
    }"
  ></div>
  <div class="close-modal" mat-dialog-close>
    <i class="far fa-times"></i>
  </div>
  <div class="details-content" [formGroup]="form" *ngIf="data && data.product">
    <div class="details-header">
      <p class="details-header__title">{{ data.product.name }}</p>
      <input-counter
        (handleDecrease)="onCounterChange(false)"
        (handleIncrease)="onCounterChange(true)"
        [counter]="productCount"
      ></input-counter>
    </div>
    <div class="details-properties" *ngIf="hasProperties()">
      <div
        class="details-properties__item"
        *ngFor="let item of getProperties()"
      >
        <img [src]="item.icon" [alt]="item.icon" />
        <p class="details-properties__item--title">{{ item.name }}</p>
      </div>
    </div>
    <div class="details-header__description">
      <p style="white-space: pre-line">{{ data.product.description }}</p>
    </div>

    <div class="details-header__comment">
      <mat-form-field
        class="example-full-width"
        *ngIf="showProductComment"
        appearance="outline"
      >
        <mat-label>{{ 'menuitem.leaveComment' | translate }}</mat-label>
        <textarea
          formControlName="comment"
          matInput
          [placeholder]="'menuitem.placeholderComment' | translate"
        ></textarea>
      </mat-form-field>
      <div>
        <button (click)="removeComment()">
          <span *ngIf="!showProductComment && !isBevarage">{{
            'menuitem.addComment' | translate
          }}</span>
          <span *ngIf="showProductComment">{{
            'menuitem.removeComment' | translate
          }}</span>
        </button>
      </div>
    </div>

    <div class="details-variants" *ngIf="data.product.variants.length > 1">
      <div *ngIf="data.product.variants.length <= 2">
        <mat-button-toggle-group
          name="fontStyle"
          aria-label="Font Style"
          (change)="onVariantChange($event)"
          [value]="selectedVariantId"
        >
          <mat-button-toggle
            *ngFor="let item of data.product.variants"
            [value]="item.variantId"
            class="coloredVariant"
          >
            CHF {{ item.price | number: '1.2-2' }} - {{ item.name }}
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div *ngIf="data.product.variants.length > 2">
        <mat-radio-group
          aria-labelledby="example-radio-group-label"
          class="extra-radio-group"
          (change)="onVariantChange($event)"
          [value]="selectedVariantId"
        >
          <div class="extras-item" *ngFor="let option of data.product.variants">
            <mat-radio-button
              class="extra-radio-button"
              [value]="option?.variantId"
            >
              CHF {{ option.price | number: '1.2-2' }} - {{ option.name }}
            </mat-radio-button>
          </div>
        </mat-radio-group>
      </div>
    </div>

    <div *ngIf="data.product.extras" class="extras-padding">
      <div
        class="details-extras"
        *ngFor="let item of data.product.extras; index as i"
      >
        <p class="details-extras__title">
          {{ item.name }}
        </p>
        <p
          *ngIf="item.isRequired"
          class="details-extras__title"
          [class.highlight]="
            highlightUnselectedExtras &&
            item.isRequired &&
            !checkIfExtraIsSelected(item.extraId)
          "
        >
          ({{ 'global.mandatoryLabel' | translate }})
          <i
            class="fa-regular fa-circle-info"
            [matTooltip]="
              'global.selectMinimumOptions'
                | translate: { extraTitle: item.name }
            "
          ></i>
        </p>

        <div *ngIf="item.selectionType === 'single'">
          <mat-radio-group
            aria-labelledby="example-radio-group-label"
            (change)="onSingleExtraChange($event, i)"
            class="extra-radio-group"
          >
            <div class="extras-item">
              <mat-radio-button
                *ngIf="!item.isRequired"
                class="extra-radio-button"
                [value]="null"
              >
                <span>
                  {{ 'menuitem.noChoice' | translate }}
                </span>
              </mat-radio-button>
            </div>
            <div
              class="extras-item"
              *ngFor="let option of item.options; index as extraIndex"
            >
              <mat-radio-button class="extra-radio-button" [value]="extraIndex">
                <span>
                  {{ option.name }}
                </span>
              </mat-radio-button>
              <div class="extras-item-price">
                <input-counter
                  [minValue]="
                    option.minimumRequiredQuantity
                      ? option.minimumRequiredQuantity
                      : 0
                  "
                  [maxValue]="
                    option.maximumAllowedQuantity
                      ? option.maximumAllowedQuantity
                      : 1
                  "
                  *ngIf="optionHasRangeAndSelected(option)"
                  (handleDecrease)="onExtraCounterChange(false, option, item)"
                  (handleIncrease)="onExtraCounterChange(true, option, item)"
                  [counter]="option.quantity"
                ></input-counter>
                <p class="priceClass">
                  + CHF
                  {{ option.price | number: '1.2-2' }}
                </p>
              </div>
            </div>
          </mat-radio-group>
        </div>

        <div *ngIf="item.selectionType !== 'single'">
          <div
            class="garnish-item"
            *ngFor="let option of item.options; index as extraIndex"
          >
            <mat-checkbox
              class="custom-checkbox"
              (change)="onMultipleExtraChange($event, i)"
              [value]="extraIndex.toString()"
            >
              <p class="garnish-item--title">{{ option.name }}</p>
            </mat-checkbox>
            <div style="display: flex">
              <input-counter
                [minValue]="
                  option.minimumRequiredQuantity
                    ? option.minimumRequiredQuantity
                    : 0
                "
                [maxValue]="
                  option.maximumAllowedQuantity
                    ? option.maximumAllowedQuantity
                    : 1
                "
                *ngIf="optionHasRangeAndSelected(option)"
                (handleDecrease)="onExtraCounterChange(false, option, item)"
                (handleIncrease)="onExtraCounterChange(true, option, item)"
                [counter]="option.quantity"
              ></input-counter>
              <p class="priceClass">
                + CHF {{ option.price | number: '1.2-2' }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="bottom-button-container">
  <button (click)="saveProductToCart()">
    {{ 'global.addButton' | translate }} CHF
    {{ getTotalPrice | number: '1.2-2' }}
  </button>
</div>
