import { Component, OnDestroy, OnInit } from '@angular/core';
import { BusinessFacade } from '../../../core/facades/business.facade';
import { BusinessStore } from '../../../core/models/business-store.model';
import { ActivatedRoute, Router } from '@angular/router';
import { HelperService } from '../../../core/services/helper.service';
import { filter, Subject, take, takeUntil, tap } from 'rxjs';
import { ActivatedModule } from '../../../core/enums/activated-module.enum';
import { environment } from 'apps/orderapp/src/environments/environment';
import { PaymentService } from '../../../core/services/payment.service';
import { FormatBusinessName } from '../../../shared/utility/format-title';
import { serviceTypes } from '../../../core/constants/service-type';
import { QrcodeService } from '../../../core/services/qrcode.service';
import { PaymentMethod } from '../../../core/enums/payment-method.enum';
import { Location } from '@angular/common';
import {
  BusinessServiceTypeResponseModel,
  QrcodeResponse,
} from '@orderapp/api-clients/orderadmin-api-client';
import { ServiceTypeUtil } from '../../../core/utils/service-type.util';
import { getServiceModuleByUrl } from '../../../shared/utility/get-activated-service-module';
import { SessionStorageKeys } from '../../../core/constants/session-storage-keys';

@Component({
  selector: 'home-inhouse',
  templateUrl: 'inhouse.component.html',
  styleUrls: ['inhouse.component.scss'],
})
export class HomeInhouseComponent implements OnDestroy, OnInit {
  public readonly modules = [
    ActivatedModule.TakeAway,
    ActivatedModule.Delivery,
  ];

  private readonly _destroy$ = new Subject();
  businessStore: BusinessStore = {} as BusinessStore;
  isInhouse = false;
  serviceTypes!: BusinessServiceTypeResponseModel[];
  currentActivatedModule!: ActivatedModule;
  showPayTeaser = false;
  openTableId: string | null = null;

  constructor(
    public readonly businessFacade: BusinessFacade,
    private readonly _route: ActivatedRoute,
    public readonly helperService: HelperService,
    public readonly paymentService: PaymentService,
    public readonly _qrCodeService: QrcodeService,
    private _router: Router,
    private _location: Location,
  ) {}

  ngOnInit(): void {
    this._route.params
      .pipe(
        takeUntil(this._destroy$),
        tap((params) => {
          const businessStore = params as BusinessStore;
          this.cleanSessionStorage();
          this.getQrCodeDetails(Number(businessStore.qrCodeId), businessStore);
        }),
      )
      .subscribe();
    this.businessFacade.resetCart();
  }

  private handleRouteParams(businessStore: BusinessStore): void {
    this.businessFacade.setBusinessStore(businessStore);
    this.businessFacade.fetchOrderingTimes(
      Number(businessStore.businessId),
      serviceTypes.Inhouse.name,
    );
    this.businessFacade.fetchPreparationTimes(
      Number(businessStore.businessId),
      serviceTypes.Inhouse.name,
    );
    this.businessFacade.fetchMenuProducts(
      Number(businessStore.businessId),
      serviceTypes.Inhouse.name,
      Number(businessStore.qrCodeId) || null,
    );

    this.businessStore = businessStore;
  }

  getQrCodeDetails(qrCodeId: number, businessStore: any) {
    this._qrCodeService.getQrcodeDetails(qrCodeId).subscribe((result) => {
      if (!result.isActive) {
        this.businessFacade.setBusinessIsClosed(true);
      } else {
        this.getServiceTypeAndCheckCashPaymentActive(businessStore, result);
        if (result.openTableId) {
          this.showPayTeaser = true;
          this.openTableId = result.openTableId;
        }
        this.handleRouteParams({
          ...businessStore,
          serviceTypeId: result?.serviceTypeId,
        });
      }
    });
  }

  getIsCashPaymentMethodActive(businessId: number) {
    this.paymentService
      .getPaymentMethod(
        businessId,
        serviceTypes.Inhouse.name,
        PaymentMethod.Cash,
      )
      .subscribe((res) => {
        sessionStorage.setItem(
          'isCashPaymentMethodActive',
          String(res?.isActive) || 'false',
        );
      });
  }

  redirectToMenu(): string {
    let menuRoute = '';
    this.businessFacade.menuProducts$.data$
      .pipe(take(1))
      .subscribe((menuProducts) => {
        const serviceType = Object.values(serviceTypes).find(
          (type) => type.id === this.businessStore.serviceTypeId,
        )?.name as ActivatedModule;
        if (
          serviceType.toLowerCase() === ActivatedModule.TakeAway.toLowerCase()
        ) {
          console.log('MenProducts Takeaway: ', menuProducts?.menus?.length);
          if (menuProducts?.menus?.length == 0) {
            this.businessFacade.setBusinessIsClosed(true);
          }
          if (menuProducts?.menus?.length == 1) {
            this.businessFacade.setSelectedMenuId(
              menuProducts?.menus[0].menuId ?? 0,
            );
            if (menuProducts?.menus[0].foodProducts.length > 0) {
              menuRoute = this.businessFacade.goToServiceHome(
                'food-menu',
                this.currentActivatedModule,
              );
            } else {
              menuRoute = this.businessFacade.goToServiceHome(
                '/beverage-menu',
                this.currentActivatedModule,
              );
            }
          }
          if (menuProducts?.menus && menuProducts.menus.length > 1) {
            menuRoute = this.businessFacade.goToServiceHome(
              'menu-selection',
              this.currentActivatedModule,
            );
          }
        } else {
          console.log('MenProducts Inhouse: ', menuProducts?.menus?.length);
          if (menuProducts?.menus?.length == 0) {
            this.businessFacade.setBusinessIsClosed(true);
          }
          if (menuProducts?.menus?.length == 1) {
            this.businessFacade.setSelectedMenuId(
              menuProducts?.menus[0].menuId ?? 0,
            );
            if (menuProducts?.menus[0].foodProducts.length > 0) {
              menuRoute = this.businessFacade.goToInhouseHome('food-menu');
            } else {
              menuRoute = this.businessFacade.goToInhouseHome('beverage-menu');
            }
          }
          if (menuProducts?.menus && menuProducts.menus.length > 1) {
            menuRoute = this.businessFacade.goToInhouseHome('menu-selection');
          }
        }
      });
    return menuRoute;
  }

  redirectToPaymentSumary() {
    return this._router.navigate(
      [`${decodeURIComponent(this._location.path())}/shopping-cart`],
      {
        queryParams: { payTeaser: true, openTableId: this.openTableId },
      },
    );
  }

  public ngOnDestroy(): void {
    this._destroy$.next(null);
    this._destroy$.complete();
  }
  cleanSessionStorage() {
    sessionStorage.removeItem('order-numbers');
    sessionStorage.removeItem('payTeaser');
    sessionStorage.removeItem('openTableId');
    sessionStorage.removeItem(SessionStorageKeys.MenuId);
  }

  checkIfQrcodeIsNumber(qrCodeValue: any): boolean {
    const parsedValue = parseFloat(qrCodeValue);
    const result = typeof parsedValue === 'number';
    return result;
  }
  getServiceTypeAndCheckCashPaymentActive(
    businessStore: BusinessStore,
    result: QrcodeResponse,
  ) {
    this.businessStore.serviceTypeId = result.serviceTypeId ?? 0;
    this.businessFacade.serviceTypes$.data$
      .pipe(
        tap((item) => {
          //on back or missing serviceTypeId
          if (item == null || this.businessStore.serviceTypeId === undefined) {
            const serviceModule = getServiceModuleByUrl(
              this._location.path(),
            ) as unknown as number;
            this.businessStore.serviceTypeId = serviceModule;
          }
        }),
        filter((item) => item !== null && item !== null && item.length > 0),
        filter(
          () =>
            this.businessStore &&
            this.businessStore.serviceTypeId !== undefined,
        ),
        take(1),
        tap((item) => {
          this.businessFacade.setBusinessStore(businessStore);
          this.currentActivatedModule = Object.values(item).find(
            (type) => type.serviceTypeId === this.businessStore.serviceTypeId,
          )?.name as ActivatedModule;
          console.log('currentActivatedModule: ', this.currentActivatedModule);
          this.serviceTypes = item?.filter(
            (module) =>
              module.serviceTypeId === this.businessStore.serviceTypeId,
          );
          console.log('serviceTypes: ', this.serviceTypes);
        }),
      )
      .subscribe(() => {
        this.getIsCashPaymentMethodActive(Number(businessStore.businessId));

        sessionStorage.setItem(
          'home-url',
          `/${environment.prePath}${businessStore.businessType}/${businessStore.city}/${businessStore.businessName}/${businessStore.businessId}/${ServiceTypeUtil.url(this.serviceTypes[0])}/${businessStore.qrCodeId}`,
        );
        document.title = FormatBusinessName(
          businessStore.businessName,
          'Vor Ort',
        );
      });
  }
}
