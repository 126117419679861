import { Injectable } from '@angular/core';
import { serviceTypes } from '../constants/service-type';
import { ActivatedModule } from '../enums/activated-module.enum';
import { TranslateService } from '@ngx-translate/core';

const DEFAULT_ICON_CLASSES = 'fa-duotone fa-duotone-primary';

@Injectable({ providedIn: 'root' })
export class TypeService {
  name = '' as ActivatedModule;

  constructor(private _translate: TranslateService) {}

  public get icon(): string {
    switch (this.name?.toLowerCase()) {
      case serviceTypes[ActivatedModule.TakeAway].name.toLowerCase():
        return `${DEFAULT_ICON_CLASSES} fa-person-carry-box`;
      case serviceTypes[ActivatedModule.Inhouse].name.toLowerCase():
        return `${DEFAULT_ICON_CLASSES} fa-map-marker-check`;
      case serviceTypes[ActivatedModule.Delivery].name.toLowerCase():
        return `${DEFAULT_ICON_CLASSES} fa-moped`;
      case serviceTypes[ActivatedModule.HotelService].name.toLowerCase():
        return `${DEFAULT_ICON_CLASSES} fa-hotel`;
      default:
        return '';
    }
  }

  public title(name: string): string {
    switch (name?.toLowerCase()) {
      case serviceTypes[ActivatedModule.TakeAway].name.toLowerCase():
        return this._translate.instant('home.moduleTitleTakeaway');
      case serviceTypes[ActivatedModule.Inhouse].name.toLowerCase():
        return this._translate.instant('home.moduleTitleInhouse');
      case serviceTypes[ActivatedModule.Delivery].name.toLowerCase():
        return this._translate.instant('home.moduleTitleDelivery');
      case serviceTypes[ActivatedModule.HotelService].name.toLowerCase():
        return this._translate.instant('home.moduleTitleHotelService');
      case serviceTypes[ActivatedModule.Pickup].name.toLowerCase():
        return this._translate.instant('home.moduleTitlePickUp');

      default:
        return '';
    }
  }

  public description(name: string): string {
    switch (name?.toLowerCase()) {
      case serviceTypes[ActivatedModule.TakeAway].name.toLowerCase():
        return this._translate.instant('home.moduleDescriptionTakeaway');
      case serviceTypes[ActivatedModule.Inhouse].name.toLowerCase():
        return this._translate.instant('home.moduleDescriptionInhouse');
      case serviceTypes[ActivatedModule.Delivery].name.toLowerCase():
        return this._translate.instant('home.moduleDescriptionDelivery');
      case serviceTypes[ActivatedModule.HotelService].name.toLowerCase():
        return this._translate.instant('home.moduleDescriptionInhouse');
      case serviceTypes[ActivatedModule.Pickup].name.toLowerCase():
        return this._translate.instant('home.moduleDescriptionPickUp');
      default:
        return '';
    }
  }
}
